<script>
import { mapActions, mapState } from "vuex";
import TablaLista from "../../../components/tablaLista.vue";
export default {
  data() {
    return {
      idSeccion: "",
      headersTabla: ["ID", "Imagen", "Nombre", "Categoría", "Acciones"],
      campos: [
        { tipo: "texto", clave: "id" },
        { tipo: "imagen", clave: "imagen" },
        { tipo: "texto", clave: "nombre", bold: true },
        { tipo: "texto", clave: "categoria" },
        { tipo: "boton", funcion:"remover" },
      ],
      productos: [],
      nombreSeccion: "",
    };
  },
  components: {
    TablaLista,
  },
  mounted() {
    this.idSeccion = this.$route.params.id;
    this.consultarUnaSeccion(this.idSeccion);
  },
  computed: {
    ...mapState("comercio", ["seccion"]),
  },
  methods: {
    ...mapActions("comercio", [
      "consultarUnaSeccion",
      "actualizarNombreSeccion",
      "removerProductoSeccion"
    ]),
    eliminarElementoSeccion(elemento) {
        
      this.$swal({
        title: "¿Estás seguro de remover este producto de la sección?",
        text: `El producto seleccionado es: ${elemento?.nombre}`,
        icon: "warning",
        showDenyButton: true,
        denyButtonColor: "#d33",
        confirmButtonText: "Si, remover!",
        confirmButtonColor: "#10B981",
        denyButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerProductoSeccion({seccion: this.idSeccion, idProducto:elemento.id});
        }
      });
    },
  },
  watch: {
    seccion(nuevaSeccion) {
      this.nombreSeccion = "";
      if (this.nombreSeccion == "") {
        this.nombreSeccion = this.seccion.Nombre;
      }
      if(!nuevaSeccion.Productos){
        this.productos = [];
      } else {
          this.productos = Object.values(nuevaSeccion.Productos);
          this.productos = this.productos.map((producto) => {
            return {
              id: producto.ProductoID,
              nombre: producto.Data.DESCRIPCION,
              categoria: producto.Data.CATEGORIAAPP,
              imagen: producto.Data.URLS512,
            };
          });
      }
    },
  },
};
</script>
<template>
  <Breadcrumbs main="Secciones" :title="seccion.Nombre" />
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="row d-flex align-items-center">
          <div class="col-lg-5 col-sm-12 mb-3">
            <input
              class="form-control"
              id="nombreSeccion"
              type="text"
              placeholder="Nombre de la sección"
              v-model.trim="nombreSeccion"
            />
          </div>
          <div class="col-lg-2 col-sm-6 mb-3">
            <button
              class="btn btn-primary"
              type="button"
              @click="
                actualizarNombreSeccion({
                  nombre: this.nombreSeccion,
                  id: this.idSeccion,
                })
              "
            >
              Actualizar
            </button>
          </div>
          <div class="col-lg-5 col-sm-6 mb-3 d-flex justify-content-end">
            <router-link
              class="btn btn-primary"
              style="
                background-color: #7e22ce !important;
                border-color: #7e22ce !important;
              "
              :to="{path:`/agregar-productos/${this.idSeccion}/${this.nombreSeccion}`, props:{nombreSeccion:'hola'}}"
              
            >
              Agregar productos
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body">
        <TablaLista
          :data="productos"
          :headers="headersTabla"
          :search="false"
          :campos="campos"
          @removerElemento = "eliminarElementoSeccion"
        ></TablaLista>
      </div>
    </div>
  </div>
</template>
