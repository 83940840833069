<template>
  <Breadcrumbs title="Selección de artículos" />
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <div>
              <!-- Comercio: {{ pedidosComercio }} -->
              <label>Seleccione una Categoria:</label>
              <select
                class="form-select digits"
                v-model="selectedSeccion"
                @change="actualizarRutaProductos"
              >
                <option value="" :disabled="true">
                  Selecciona una Categoria
                </option>
                <option
                  v-for="(seccion, key) in secciones"
                  :key="key"
                  :value="key"
                >
                  {{ seccion }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-4 col-0"></div>

          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por fecha:</label>
            <datepicker
              :disabled="loading"
              class="datepicker-here digits"
              range
              id="fechaPedidos"
              :enable-time-picker="false"
              type="date"
              locale="es"
              format="dd/MM/yyyy"
              select-text="Confirmar"
              cancel-text="Cancelar"
              v-model="RangodeFechas"
              @onDateChange="actualizarFechas"
              @onShow="limpiarTabla"
            />
          </div>
          <div>
            <h3 v-if="selectedSeccion">
              {{ `Productos ${secciones[selectedSeccion]}` }}
            </h3>

            <div class="table-container">
              <SpinnerLoading v-if="loading" />
              <table v-if="!loading">
                <thead class="text-center">
                  <tr>
                    <th>MES</th>
                    <th>NOMBRE PRODUCTO</th>
                    <th>ID PRODUCTO</th>
                    <th>CANTIDAD DE TICKETS DE VENTA</th>
                    <th>CANTIDAD DE COMPRA POR TICKET</th>
                    <th>VENTAS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="producto in productos" :key="producto.ProductoID">
                    <td>
                      {{
                        fechaInicio.toLocaleDateString("en-US", {
                          month: "short",
                          year: "numeric",
                        })
                      }}
                    </td>
                    <td>{{ producto.Data.DESCRIPCION }}</td>
                    <td class="text-center">{{ producto.ProductoID }}</td>
                    <td class="text-center">
                      {{ totalProducto(producto.ProductoID) }}
                    </td>
                    <td class="text-center">
                      {{ totalCantidadProducto(producto.ProductoID) }}
                    </td>
                    <td class="text-center">
                      $ {{ totalDineroProducto(producto.ProductoID) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <br />
      

        <div class="d-lg-flex justify-content-lg-between align-items-lg-center d-block mx-4 mb-3">
          <div>
            <h5>TOTAL DE VENTAS: {{ sumaTotal }}</h5>
            <h5>TOTAL DE PEDIDOS: {{ totalPedidos }}</h5>
            <h5>MONTO TOTAL DE PEDIDOS: ${{ montoTotal }} MXN</h5>
          </div>
            <button
              style="height: 3rem;"
              type="button"
              class="btn btn-outline-success"
              @click="exportToExcel"
              :disabled="!botonHabilitado"
            >
              Descargar Excel
            </button>
        </div>
      
    </div>
  </div>
  <!-- <pre>
    {{ pedidosComercio }}
  </pre> -->
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { getDatabase, ref as dbRef, onValue, get } from "firebase/database";
import * as XLSX from "xlsx";
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import { useStore } from "vuex";
import moment from "moment";

const store = useStore();
const pedidosComercio = computed(() => store.state.comercio.pedidos);

//rutas dinamicas
const selectedSeccion = ref("");
const secciones = {
  Metricas: "Metricas",
  SEC1: "Verduras",
  SEC2: "Frutas",
  SEC3: "Limpieza e higiénicos",
  SEC4: "Selección de la Casa",
};
const db = getDatabase();
const rutaProductosBase =
  "/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Seccion_uno/";
const rutaProductos = ref(
  `/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Seccion_uno/SECC4/Productos/`
);
const rutaPedidos = "/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Pedidos/";
const productos = ref([]);

let RangodeFechas = ref([new Date(), new Date()]);
let primerDiaDelMes = new Date();
primerDiaDelMes.setDate(1);
primerDiaDelMes.setHours(0, 0, 0, 0);
RangodeFechas.value[0] = primerDiaDelMes;
let fechaInicio = ref(primerDiaDelMes);
let fechaFin = ref(new Date());
fechaFin.value.setHours(23, 59, 59, 999);

let pedidos = {};
const totalPedidos = ref(0);
const sumaTotal = ref(0);
const totalCantidadEnPedidos = ref(0);
const montoTotal = ref(0);

const botonHabilitado = ref(false);
const loading = ref(false);

const limpiarTabla = () => {
  productos.value = [];
};

const consultarPedidos = async (ruta, fechaInicio, fechaFin) => {
  store.dispatch("comercio/consultarPedidosMetricas", {
    rutaPedidos: ruta,
    fechaInicioPedido: fechaInicio,
    fechaFinPedido: fechaFin,
  });

};

const actualizarRutaProductos = async () => {
  try {
    
    loading.value = true;
    productos.value = [];
    rutaProductos.value = `${rutaProductosBase}${selectedSeccion.value}/Productos/`;

    const snapshot = await get(dbRef(db, rutaProductos.value));
    const datos = snapshot.val();
    if (datos) {
      const listaProductos = Object.values(datos).map((producto) => ({
        ProductoID: producto.ProductoID,
        Data: {
          DESCRIPCION: producto.Data.DESCRIPCION,
        },
      }));
      productos.value = listaProductos;
      
    }
  } catch (error) {
    console.error("Error al obtener datos:", error);
  } finally {
    loading.value = false;
    botonHabilitado.value = validarSeleccion();
  }
};

const validarSeleccion = () => {
  return selectedSeccion.value !== "" && RangodeFechas.value;
};
const valiMismoMes = () => {
  const inicio = RangodeFechas.value[0];
  const fin = RangodeFechas.value[1];

  return (
    moment(inicio).month === moment(fin).month &&
    moment(inicio).year === moment(fin).year
  );
};

const actualizarFechas = async (dates) => {
  if (Array.isArray(dates) && dates.length === 2) {
    let inicio = new Date(dates[0]);
    let fin = new Date(dates[1]);

    // Validar que las fechas estén en el mismo mes
    if (moment(inicio).month === moment(fin).month) {
      // fechaInicio.value = new Date(inicio);
      loading.value = true;
      limpiarTabla();
      // actualizarTotalPedidos();
      // TotalVentas();
      // botonHabilitado.value = validarSeleccion();
      consultarPedidos(rutaPedidos, inicio, fin);
      loading.value = false;
    } else {
      alert("ERROR EL RANGO DE FECHAS DEBE SER DEL MISMO MES");
    }
  } else {
    console.error("Error No proporcionó un rango de fechas válido.");
  }
};

const totalProducto = (productoID) => {
  if (valiMismoMes()) {
    if (RangodeFechas.value[0] && RangodeFechas.value[1]) {
      if (!pedidos) return 0;
      const pedidosEnRango = Object.values(pedidosComercio.value);
      const total = pedidosEnRango.reduce((count, pedido) => {
        const productosPedido = pedido.Productos;
        const productosKeys = Object.keys(productosPedido);
        const productosNumericos = productosKeys
          .map((key) => key.replace(/[^\d]/g, ""))
          .filter((numero) => numero === productoID.toString());
        count += productosNumericos.length;
        return count;
      }, 0);
      return total;
    } else {
      console.error("Error: fechas Seleccionadas no es válida");
      return 0;
    }
  } else {
    console.error("Error: Las fechas seleccionadas no están en el mismo mes.");
    return 0;
  }
};
const totalCantidadProducto = (productoID) => {
  if (valiMismoMes()) {
    if (!pedidos || !RangodeFechas.value[0] || !RangodeFechas.value[1])
      return 0;

    const pedidosEnRango = Object.values(pedidosComercio.value);
    // const pedidosEnRango = Object.values(pedidos).filter((pedido) => {
    //   const fechaPedido = pedido.TimeSolicitud;
    //   return (
    //     fechaPedido >= RangodeFechas.value[0] &&
    //     fechaPedido <= RangodeFechas.value[1]
    //   );
    // });
    const total = pedidosEnRango.reduce((count, pedido) => {
      const productosPedido = pedido.Productos;
      const productosKeys = Object.keys(productosPedido);
      const productosFiltrados = productosKeys.filter((key) =>
        key.includes(productoID)
      );
      const cantidadTotal = productosFiltrados.reduce((sum, key) => {
        const producto = productosPedido[key];
        const cantidadGRPZ = producto
          ? parseInt(producto.CantidadGRPZ) || 0
          : 0;
        return sum + cantidadGRPZ;
      }, 0);
      count += cantidadTotal;
      return count;
    }, 0);
    return total;
  } else {
    console.error("Error: Las fechas seleccionadas no están en el mismo mes.");
    return 0;
  }
};
const totalDineroProducto = (productoID) => {
  if (valiMismoMes()) {
    if (!pedidos || !RangodeFechas.value[0] || !RangodeFechas.value[1])
      return 0;

    const pedidosEnRango = Object.values(pedidosComercio.value);
    const total = pedidosEnRango.reduce((count, pedido) => {
      const productosPedido = pedido.Productos;
      const productosKeys = Object.keys(productosPedido);
      const productosFiltrados = productosKeys.filter((key) =>
        key.includes(productoID)
      );
      const cantidadTotal = productosFiltrados.reduce((sum, key) => {
        const producto = productosPedido[key];
        const Total = producto ? parseFloat(producto.Total) || 0 : 0;
        return sum + Total;
      }, 0);
      count += cantidadTotal;
      return count;
    }, 0);
    return total;
  } else {
    console.error("Error: Las fechas seleccionadas no están en el mismo mes.");
    return 0;
  }
};
//Bien
const TotalVentas = () => {
  
  sumaTotal.value = productos.value.reduce(
    (sum, producto) => sum + totalCantidadProducto(producto.ProductoID),
    0
  );
  
};
//Bien
const actualizarTotalPedidos = () => {
  
  if (!pedidos || !RangodeFechas.value[0] || !RangodeFechas.value[1]) return;

  totalPedidos.value = Object.values(pedidosComercio.value).length;
  

  totalCantidadEnPedidos.value = Object.values(pedidosComercio.value).reduce(
    (total, pedido) => total + pedido.cantidad,
    0
  );
};

const exportToExcel = () => {
  try {
    const data = productos.value.map((producto) => [
      fechaInicio.value.toLocaleDateString(),
      producto.Data.DESCRIPCION,
      producto.ProductoID,
      totalProducto(producto.ProductoID),
      totalCantidadProducto(producto.ProductoID),
      "$ " + totalDineroProducto(producto.ProductoID),
    ]);

    const ws = XLSX.utils.aoa_to_sheet([
      [
        "MES",
        "NOMBRE PRODUCTO",
        "ID PRODUCTO",
        "CANTIDAD DE TICKETS DE VENTA",
        "CANTIDAD DE COMPRA POR TICKET",
        "VENTAS",
      ],
      ...data,
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "DATOS MAESTROS");
    const fileName = `Reporte_${secciones[selectedSeccion.value]}.xlsx`;

    XLSX.writeFile(wb, fileName);
  } catch (error) {
    console.error("Error al exportar a Excel:", error);
  }
};

const montoTotalProductos = () => {
  
  montoTotal.value = productos.value.reduce(
    (sum, producto) => sum + totalDineroProducto(producto.ProductoID),
    0
  );
  
};

onMounted(() => {
  // onValue(referenciaPedidos, (snapshot) => {
  //   pedidos = snapshot.val();
  //   actualizarTotalPedidos();
  //   TotalVentas();
  //   botonHabilitado.value = validarSeleccion();
  // });

  // watch(
  //   () => productos.value,
  //   () => {
  //     TotalVentas();
  //   }
  // );
  // actualizarFechas();

  selectedSeccion.value = "Metricas";
  actualizarRutaProductos();

  const inicioMes = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
  const fechaActual = moment().format("YYYY-MM-DD HH:mm:ss");
  
  
  RangodeFechas.value = [inicioMes, fechaActual];
});
watch(
  () => RangodeFechas.value,
  (nuevasFechas) => {
    actualizarFechas(nuevasFechas);
  }
);
watch(pedidosComercio, () => {
  actualizarTotalPedidos();
  botonHabilitado.value = validarSeleccion();
  actualizarRutaProductos();
});

watch(
    productos,
    () => {
      TotalVentas();
      montoTotalProductos();
    }
  );
</script>

<style>
/* Estilos del spinner */
.table-container .lds-roller {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: center;
}
</style>
