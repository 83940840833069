<script>
/* eslint-disable */
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  props: ["urlSiguiente", "activacion"],
  data() {
    return {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      telefono: "",
      correo: "",
      codigoPostal: "",
      fechaNacimiento: "",
      tarjeta: "",
      sexo: "",
      terminos: false,
      noCorreo: false,
      botonDesactivado: false,
      numTarjetaActivado: true,
      listaEmpleados: [],
      recomendo: "SIN RECOMENDACIÓN",
      correoOpcional: false
    };
  },
  created() {
    this.consultarConfiguracionesCorreo()
    if (this.activacion) {
      this.revisarIdActivacionTarjeta();
    }
    if (!this.activacion) {
      this.verificarVigenciaListadoEmpleados();
    }
  },
  computed: {
    ...mapState("configuracion", ["configuracionesCorreo"]),
    ...mapGetters("autenticacion", ["getUsuarioActual"]),
  },
  methods: {
    ...mapActions("configuracion", [
      "consultarConfiguracionCorreoDefecto",
    ]),
    ...mapActions("validacion", [
      "consultarEmpleados",
      "buscarTarjeta",
      "consultarTarjetaActivacion",
    ]),
    async consultarConfiguracionesCorreo(){
      try {
        await this.consultarConfiguracionCorreoDefecto()
      } catch (error) {
        (error);
      }
    },
    async revisarIdActivacionTarjeta() {
      try {
        const fechaActualActivacion = moment().format("YYYY-MM-DDTHH:mm:ss");
        (fechaActualActivacion);
        if (this.$route.params.numTarjeta) {
          this.tarjeta = this.$route.params.numTarjeta;
          this.numTarjetaActivado = false;
          ("Fecha");
          const fecha = moment().format("YYYY-MM-DD");
          (fecha);
          const tarjetaActivacion = await this.consultarTarjetaActivacion({
            tarjeta: this.tarjeta,
            fechaActual: fecha,
          });
          (tarjetaActivacion);
          if (!tarjetaActivacion[0]) {
            this.$swal({
              icon: "error",
              type: "error",
              title: "Oops...",
              text: "La tarjeta no cumple con las condiciones para la activación",
              allowOutsideClick: false,
            }).then((result) => {
              window.location.replace("https://www.superkompras.com.mx");
            });
          } else {
            const objetoDatosActivacion = {
              saldo: tarjetaActivacion[0]?.mon_act,
              descripcion: tarjetaActivacion[0]?.desc_act,
            };
            (objetoDatosActivacion);
            localStorage.setItem(
              "activacion",
              JSON.stringify(objetoDatosActivacion)
            );
          }
        }
      } catch (errores) {
        this.$swal({
          icon: "warning",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        });
      }
    },
    validarFormularioVerificacion() {
      this.botonDesactivado = true;
      const errores = [];
      const patronCorreo =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const patronCodPostal = /^\d{5}$/;
      const patronFecha = /^\d{2}\/\d{2}\/\d{4}$/;
      (this.tarjeta);
      const prefijoTarjeta = this.tarjeta.toString().substring(0, 2);
      (prefijoTarjeta);

      if (this.nombre == "") {
        errores.push({ msg: "Proporcione un nombre" });
      }
      if (this.apellidoPaterno == "") {
        errores.push({ msg: "Proporcione un apellido paterno" });
      }
      if (this.apellidoMaterno == "") {
        errores.push({ msg: "Proporcione un apellido materno" });
      }
      if (this.telefono == "") {
        errores.push({ msg: "Proporcione un teléfono" });
      }

      if (this.correo == "") {
        errores.push({ msg: "Proporcione un correo" });
      }
      if (this.codigoPostal == "") {
        errores.push({ msg: "Proporcione un código postal" });
      }
      if (this.formatoFechaNacimiento == "") {
        errores.push({ msg: "Proporcione una fecha de nacimiento" });
      }
      if (this.tarjeta == "") {
        errores.push({ msg: "Proporcione una tarjeta" });
      }
      if (this.sexo == "") {
        errores.push({ msg: "Proporcione un sexo" });
      }

      if (
        isNaN(this.telefono.toString()) ||
        this.telefono.toString().length != 10
      ) {
        errores.push({ msg: "Teléfono inválido" });
      }

      if (!patronCorreo.test(this.correo)) {
        errores.push({ msg: "Correo inválido" });
      }

      if (!patronCodPostal.test(this.codigoPostal.toString())) {
        errores.push({ msg: "Código postal inválido" });
      }
      ("TESTTTT");
      if (!patronFecha.test(this.fechaNacimiento)) {
        errores.push({ msg: "Introduce una fecha valida" });
      }

      if (!this.revisarMayoriaEdad(this.formatoFechaNacimiento())) {
        errores.push({ msg: "Para continuar necesitas ser mayor de edad" });
      }

      // if (
      //   isNaN(this.tarjeta.toString()) ||
      //   (this.tarjeta.toString().length >= 12 && this.tarjeta.toString().length <= 13)
      // ) {
      //   errores.push({ msg: "Número de tarjeta inválido" });
      // }

      if (this.activacion) {
        if (
          prefijoTarjeta != "71" &&
          prefijoTarjeta != "75" &&
          prefijoTarjeta != "77"
        ) {
          errores.push({
            msg: "Número de tarjeta no corresponde a Super Kompras",
          });
        }
      } else {
        if (prefijoTarjeta == "70") {
          if (
            isNaN(this.tarjeta.toString()) ||
            this.tarjeta.toString().length != 12
          ) {
            errores.push({ msg: "Número de tarjeta inválido" });
          }
        } else {
          if (
            prefijoTarjeta != "71" &&
            prefijoTarjeta != "75" &&
            prefijoTarjeta != "77"
          ) {
            errores.push({
              msg: "Número de tarjeta no corresponde a Super Kompras",
            });
          } else {
            if (
              isNaN(this.tarjeta.toString()) ||
              this.tarjeta.toString().length != 13
            ) {
              errores.push({ msg: "Número de tarjeta inválido" });
            }
          }
        }
      }

      if (this.terminos == false) {
        errores.push({
          msg: "Para continuar es necesario aceptar los términos y condiciones",
        });
      }

      (errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "error",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        });
        this.botonDesactivado = false;
      } else {
        this.botonDesactivado = false;

        // this.mostrarAlertaNoCorreo();
        this.procesarDatosValidados();
      }
    },
    procesarDatosValidados() {
      this.botonDesactivado = false;

      const prefijoTipo = this.tarjeta.toString().substring(0, 2);
      let template = 0;
      let nombreSender = "";
      let titulo = "";
      let correoSender = "";

      switch (prefijoTipo) {
        case "70":
          template = 4975552;
          nombreSender = "Acrópolis";
          titulo = "Código de verificación Acrópolis";
          break;
        case "71":
          template = 4934261;
          nombreSender = "Super Kompras";
          titulo = "Código de verificación Super Kompras";
          break;
        case "77":
          template = 4975552;
          nombreSender = "Acrópolis";
          titulo = "Código de verificación Acrópolis";
          break;
        case "75":
          template = 4975555;
          nombreSender = "Surtitienda";
          titulo = "Código de verificación Surtitienda";
          break;
      }
      correoSender = "appsk@tiendasgarces.com";

      const objetoDatosUsuario = {
        nombre: this.nombre,
        apellidoPaterno: this.apellidoPaterno,
        apellidoMaterno: this.apellidoMaterno,
        telefono: this.telefono.toString(),
        correo: this.correo,
        codigoPostal: this.codigoPostal.toString(),
        fechaNacimiento: this.formatoFechaNacimiento(),
        tarjeta: this.tarjeta.toString(),
        sexo: this.sexo,
        correoSender: correoSender,
        nombreSender: nombreSender,
        template: template,
        titulo: titulo,
        noCorreo: this.correoOpcional ? this.noCorreo : false,
        altaTarjeta: this.$route.params.numTarjeta ? false : true,
        recomendo: this.recomendo,
        activacion: this.$route.params.numTarjeta ? true : false,
        cliente: this.$route.params.numTarjeta ? true : false,
      };
      localStorage.setItem("datosUsuario", JSON.stringify(objetoDatosUsuario));
      (objetoDatosUsuario);

      this.resetearCampos();

      this.$router.push(this.urlSiguiente);
    },
    cancelarVerificacion() {
      this.resetearCampos();
    },

    resetearCampos() {
      this.nombre = "";
      this.apellidoPaterno = "";
      this.apellidoMaterno = "";
      this.telefono = "";
      this.correo = "";
      this.codigoPostal = "";
      this.fechaNacimiento = "";
      this.noCorreo = false;
      if (!this.activacion) {
        this.tarjeta = "";
      }
      this.sexo = "";
      this.terminos = false;
    },
    revisarMayoriaEdad(fecha) {
      const fechaActual = moment().format("YYYY-MM-DD");
      const fechaActualOffsetTimestamp =
        parseInt(moment(fechaActual).format("x")) + 39600000 + 3540000;
      const fechaRevisar = moment(fecha).format("x");
      (fechaActualOffsetTimestamp);
      (fechaRevisar);
      // const fechaActual = new Date().getTime();
      // const fechaRevisar = new Date(fecha).getTime();
      // (fechaActual);
      // (fechaRevisar);
      (fechaActualOffsetTimestamp - fechaRevisar);
      return fechaActualOffsetTimestamp - fechaRevisar >= 568067623175;
    },
    formatoFechaNacimiento() {
      (this.fechaNacimiento);
      const arrayFecha = this.fechaNacimiento.split("/");
      const fechaFormatoMoment =
        arrayFecha[2] + "-" + arrayFecha[1] + "-" + arrayFecha[0];
      // (fechaFormatoMoment);
      // const fechaRecibida = moment(fechaFormatoMoment);
      // (fechaRecibida);
      // const fechaOffsetDia = parseInt(fechaRecibida.format('x')) - 86400000;
      // (fechaOffsetDia)
      // const fechaFormato = moment(fechaOffsetDia).format("YYYY-MM-DD");
      (fechaFormatoMoment);
      // (this.fechaNacimiento);
      // const fechaFormato = new Date(this.fechaNacimiento);
      // (fechaFormato);
      // const dia = fechaFormato.getDate().toString().padStart(2, "0");
      // (dia);
      // const mes = (fechaFormato.getMonth() + 1).toString().padStart(2, "0");
      // const año = fechaFormato.getFullYear();
      // (`${año}-${mes}-${dia+1}`);
      // return `${año}-${mes}-${dia}`;
      return fechaFormatoMoment;
    },
    cambiarCorreoDefecto() {
      if (!this.noCorreo) {
        this.correo = "appsk@tiendasgarces.com";
      } else {
        this.correo = "";
      }
    },
    mostrarAlertaNoCorreo() {
      this.$swal({
        title: "¿Estás seguro de que no tienes un correo?",
        text: 'En caso de seleccionar la casilla "No tengo un email" perderás el beneficio de tener una recompensa de puntos en tu tarjeta club al verificar tus datos',
        showCancelButton: true,
        cancelButtonText: "Tengo un correo",
        confirmButtonText: "Confirmar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.procesarDatosValidados();
        }
      });
    },
    darAutoFormatoFecha() {
      let fechaFormato = this.fechaNacimiento.replace(/\D/g, ""); // Elimina todos los caracteres no numéricos

      if (fechaFormato.length > 2) {
        fechaFormato =
          fechaFormato.substring(0, 2) + "/" + fechaFormato.substring(2);
      }
      if (fechaFormato.length > 5) {
        fechaFormato =
          fechaFormato.substring(0, 5) + "/" + fechaFormato.substring(5, 9);
      }

      this.fechaNacimiento = fechaFormato;
    },
    verificarVigenciaListadoEmpleados() {
      const tiempoVigencia = 24 * 60 * 60 * 1000;
      const tiempoActual = moment().format("x");
      let listaEmpleadosJson = localStorage.getItem("listaEmpleados");

      if (listaEmpleadosJson) {
        listaEmpleadosJson = JSON.parse(listaEmpleadosJson);
        (tiempoActual - listaEmpleadosJson.timestamp);
        if (tiempoActual - listaEmpleadosJson.timestamp > tiempoVigencia) {
          ("ya vencio");
          localStorage.removeItem("listaEmpleados");
        }
      }
      this.obtenerEmpleados(this.getUsuarioActual?.division);
    },
    async obtenerEmpleados(sucursal) {
      try {
        let listaEmpleadosJson = localStorage.getItem("listaEmpleados");

        if (listaEmpleadosJson) {
          listaEmpleadosJson = JSON.parse(listaEmpleadosJson);
          for (var [key, empleado] of Object.entries(
            listaEmpleadosJson.listaEmpleados
          )) {
            this.listaEmpleados.push(
              `${empleado.name} ${empleado.lastnamefirst} ${empleado.lastnamesecond}`
            );
          }
          this.listaEmpleados.push("SIN RECOMENDACIÓN");
        } else {
          const listaEmpleadosResponse = await this.consultarEmpleados(
            sucursal
          );
          (listaEmpleadosResponse);

          const timestamp = moment().format("x");

          const objetoListaEmpleados = {
            timestamp: timestamp,
            listaEmpleados: listaEmpleadosResponse,
          };

          localStorage.setItem(
            "listaEmpleados",
            JSON.stringify(objetoListaEmpleados)
          );

          for (var [key, empleado] of Object.entries(listaEmpleadosResponse)) {
            this.listaEmpleados.push(
              `${empleado.name} ${empleado.lastnamefirst} ${empleado.lastnamesecond}`
            );
          }
          this.listaEmpleados.push("SIN RECOMENDACIÓN");
        }

        (this.listaEmpleados);
      } catch (error) {
        (error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },
    async verificarTarjetaExiste() {
      try {
        const tarjetaEncontrada = await this.buscarTarjeta(this.tarjeta);
        (tarjetaEncontrada);

        if (tarjetaEncontrada) {
          this.$swal({
            icon: "warning",
            type: "warning",
            title: "Oops...",
            text: "La tarjeta proporcionada ya fue dada de alta. Ingresa una diferente.",
          });
        }
      } catch (error) {
        if (error.msg == "Tarjeta no encontrada") {
          this.validarFormularioVerificacion();
        } else {
          this.$swal({
            icon: error.code ? "error" : "warning",
            type: error.code ? "error" : "warning",
            title: error.code ? error.code : "Oops...",
            text: error.msg,
          });
        }
        (error);
      }
    },
  },
  watch:{
    configuracionesCorreo(nuevasConfiguraciones){
      const { alta, qr } = nuevasConfiguraciones;
      if(this.activacion){
        this.correoOpcional = qr;
      } else {
        this.correoOpcional = alta;
      }
    }
  }
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>
            {{
              this.activacion
                ? "Activación tarjeta club"
                : "Dar de alta Tarjetahabiente"
            }}
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-1" style="max-width: 900px">
      <form @submit.prevent="verificarTarjetaExiste()">
        <div class="card-body">
          <div class="mb-3">
            <label class="col-form-label pt-0" for="nombre">Nombre</label>
            <input
              class="form-control"
              id="nombre"
              type="text"
              aria-describedby="nombre"
              placeholder="Introduce tu nombre"
              v-model.trim="nombre"
            />
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="apellidoPaterno"
                  >Apellido paterno</label
                >
                <input
                  class="form-control"
                  id="apellidoPaterno"
                  type="text"
                  aria-describedby="apellidoPaterno"
                  placeholder="Introduce tu apellido paterno"
                  v-model.trim="apellidoPaterno"
                />
              </div>
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="apellidoMaterno"
                  >Apellido materno</label
                >
                <input
                  class="form-control"
                  id="apellidoMaterno"
                  type="text"
                  aria-describedby="apellidoMaterno"
                  placeholder="Introduce tu apellido materno"
                  v-model.trim="apellidoMaterno"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="col-form-label pt-0" for="telefono">Teléfono</label>
            <div class="row">
              <div
                class="col-lg-1 col-2 d-flex align-items-center justify-content-lg-start justify-content-center"
                style="min-height: 100%"
              >
                <label class="col-form-label" for="prefijo">+52</label>
              </div>
              <div class="col-lg-11 col-10">
                <input
                  class="form-control"
                  id="telefono"
                  type="number"
                  aria-describedby="telefono"
                  placeholder="Introduce tu teléfono"
                  v-model.trim="telefono"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="col-form-label pt-0" for="correo"
              >Correo electrónico</label
            >
            <input
              class="form-control"
              id="correo"
              type="text"
              aria-describedby="correo"
              placeholder="Introduce tu correo electrónico"
              v-model.trim="correo"
              :disabled="noCorreo"
            />
          </div>
          <div v-if="correoOpcional"
            class="checkbox checkbox-dark m-square col-form-label ms-1"
            
          >
          <!-- v-if="!activacion" -->
            <input
              id="noCorreo"
              type="checkbox"
              @click="cambiarCorreoDefecto()"
              v-model.trim="noCorreo"
            />
            <label class="mt-0" for="noCorreo">No tengo correo</label>
          </div>
          <div class="card mb-3" v-if="noCorreo && !activacion && correoOpcional">
            <div
              class="card-body p-2 bg-primary rounded text-center"
              style="background-color: #f1516e !important"
            >
              <p class="m-0 f-w-700">
                Vamos a estar auditando y si no ingresas el correo de un cliente
                te podemos penalizar, favor de soliticitarle todos los datos a
                el cliente, el correo es muy importante para tiendas Garcés.
              </p>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="codifoPostal"
                  >Código postal</label
                >
                <input
                  class="form-control"
                  id="codifoPostal"
                  type="number"
                  aria-describedby="codigoPostal"
                  placeholder="Introduce tu código postal"
                  v-model.trim="codigoPostal"
                />
              </div>
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="fechaNacimiento"
                  >Fecha de nacimiento</label
                >
                <!-- <datepicker
                  id="fechaNacimiento"
                  class="datetimepicker-input digits"
                  :enable-time-picker="false"
                  type="date"
                  locale="es"
                  select-text="Confirmar"
                  cancel-text="Cancelar"
                  format="dd/MM/yyyy"
                  v-model.trim="fechaNacimiento"
                /> -->
                <input
                  class="form-control"
                  id="fechaNacimiento"
                  type="tel"
                  aria-describedby="fechaNacimiento"
                  placeholder="DD/MM/AAAA"
                  v-model.trim="fechaNacimiento"
                  @input="darAutoFormatoFecha()"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="col-form-label pt-0" for="tarjeta"
              >Tarjeta club</label
            >
            <input
              :disabled="!numTarjetaActivado"
              class="form-control"
              id="tarjeta"
              type="number"
              aria-describedby="tarjeta"
              placeholder="Introduce tu tarjeta club"
              v-model.trim="tarjeta"
            />
          </div>

          <div class="row mb-3" v-if="!activacion">
            <div class="col">
              <label class="form-label" for="exampleFormControlSelect30"
                >Recomendo</label
              >
              <select
                class="form-select digits"
                id="exampleFormControlSelect30"
                v-model="recomendo"
              >
                <option v-for="empleado in listaEmpleados">
                  {{ empleado }}
                </option>
              </select>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="sexo">Sexo</label>
                <div class="m-t-15 custom-radio-ml">
                  <div class="form-check radio radio-primary">
                    <input
                      class="form-check-input"
                      id="masculino"
                      type="radio"
                      name="masculino"
                      value="Hombre"
                      v-model.trim="sexo"
                    />
                    <label class="form-check-label" for="masculino"
                      >Hombre</label
                    >
                  </div>
                  <div class="form-check radio radio-primary">
                    <input
                      class="form-check-input"
                      id="femenino"
                      type="radio"
                      name="femenino"
                      value="Mujer"
                      v-model.trim="sexo"
                    />
                    <label class="form-check-label" for="femenino">Mujer</label>
                  </div>
                  <div class="form-check radio radio-primary">
                    <input
                      class="form-check-input"
                      id="sinEspecificar"
                      type="radio"
                      name="sinEspecificar"
                      value="Otro"
                      v-model.trim="sexo"
                    />
                    <label class="form-check-label" for="sinEspecificar"
                      >Sin especificar</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg col-md-12 d-flex align-items-center mb-3">
                <div class="checkbox checkbox-dark m-square">
                  <div class="d-flex justify-content-start ms-1">
                    <input
                      id="terminos"
                      type="checkbox"
                      v-model.trim="terminos"
                    />
                    <label class="mt-0" for="terminos"
                      >Acepto los términos y condiciones</label
                    >
                  </div>
                  <h6 class="f-w-600">
                    <a
                      target="_blank"
                      href="https://www.superkompras.com.mx/aviso-privacidad/"
                      >Consulta nuestro aviso de privacidad</a
                    >
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!activacion">
            <p class="mt-0" for="nota">
              Estos datos deberán ser verificados por los clientes así que una
              vez agregados todos los campos y continues con el proceso se le
              enviará un email de verificación y un SMS de verificación al
              teléfono que el cliente proporcionó
            </p>
          </div>
        </div>
        <div class="card-footer text-end">
          <button
            class="btn btn-secondary me-2"
            type="button"
            @click="cancelarVerificacion()"
          >
            Cancelar
          </button>
          <button class="btn btn-primary" type="submit">Continuar</button>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
.dp__input_wrap input {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
