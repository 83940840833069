
import {
  getDatabase,
  ref,
  set,
  get,
  child,
} from "firebase/database";

const state = {
  configuracionesCorreo: null,
};

// getters
const getters = {};

// mutations
const mutations = {
  setConfiguracionesCorreo(state, nuevasConfiguraciones) {
    state.configuracionesCorreo = nuevasConfiguraciones;
    
  },
};

// actions
const actions = {
  async consultarConfiguracionCorreoDefecto({ commit }) {
    try {
      const dbRef = ref(getDatabase());
      const snapshot = await get(
        child(
          dbRef,
          `/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/ConfiguracionTarjetasClub/CorreoPorDefecto`
        )
      );
      if (snapshot.exists()) {
        commit("setConfiguracionesCorreo", snapshot.val());
      }
    } catch (error) {
      
    }
  },
  guardarConfiguracionCorreoDefecto({ commit }, objetoConfiguracion) {
    try {
      const { alta, preregistro, qr, verificacion } = objetoConfiguracion;
      
      const db = getDatabase();
      set(
        ref(
          db,
          "/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/ConfiguracionTarjetasClub/CorreoPorDefecto"
        ),
        {
          alta: alta,
          verificacion: verificacion,
          qr: qr,
          preregistro:  preregistro,
        }
      );
    } catch (error) {
      throw {
        msg: "Error al guardar configuración de correo por defecto",
        code: "SKE-134",
      };
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
