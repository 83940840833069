<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import moment from "moment";

// import TablaLista from "../../components/tablaLista.vue";
import GraficaBarras from "../../components/graficaBarras.vue";
import { arraySucursales } from "@/constants/sucursales";
export default {
  data() {
    return {
      fechaFiltradoTarjetahabientes: [],
      empleado: "",
      headersTabla: [
        "Tarjeta",
        "Id Cliente",
        "Nombre",
        "Apellido paterno",
        "Apellido materno",
        "Email",
        "Teléfono",
        "Fecha de nacimiento",
        "Código postal",
        "Sexo",
        "Recomendo",
        "Sucursal",
        "Dio alta",
        "Fecha alta",
        "Fecha verificación",
        "Pre registro",
        "Promotor",
        "Verificó email",
      ],
      campos: [
        { tipo: "texto", clave: "tarjeta" },
        { tipo: "texto", clave: "id_cli" },
        { tipo: "texto", clave: "nombre" },
        { tipo: "texto", clave: "apellidoM" },
        { tipo: "texto", clave: "apellidoP" },
        { tipo: "texto", clave: "email" },
        { tipo: "texto", clave: "telefono" },
        { tipo: "texto", clave: "fechanacim" },
        { tipo: "texto", clave: "cp" },
        { tipo: "texto", clave: "sexo" },
        { tipo: "texto", clave: "recomendo" },
        { tipo: "texto", clave: "division" },
        { tipo: "texto", clave: "dioalta" },
        { tipo: "texto", clave: "fechaaltat" },
        { tipo: "texto", clave: "fechaverif" },
        { tipo: "texto", clave: "preregistro" },
        { tipo: "texto", clave: "promotor" },
        { tipo: "texto", clave: "verificoEmail" },
      ],

      tipoSucursal: "General",
      sucursal: "",
      prefijoSuperKompras: 71,
      prefijoSuperKomprasMicro: 73,
      prefijoSurtitienda: 75,
      prefijoAcropolis: 77,
      listaTipoSucursalesPrefijo: [
        { nombre: "Super Kompras", prefijo: 71 },
        { nombre: "Super Kompras", prefijo: 73 },
        { nombre: "Surtitienda", prefijo: 75 },
        { nombre: "Acropolis", prefijo: 77 },
        { nombre: "Sin sucursal" },
      ],
      conteoTipoSucursales: [],
      listaTipoSucursales: [
        "Super Kompras",
        "Surtitienda",
        "Acropolis",
        "General",
      ],
      listaSucursalesSk: [
      ...arraySucursales.sucursalesSk,
      ...arraySucursales.sucursalesSkMicro,
        // { nombre: "SK LAS MARINAS", division: 7104 },
        // { nombre: "SK LERMA", division: 7105 },
        // { nombre: "SK TOLLOCAN", division: 7120 },
        // { nombre: "SK PINO SUAREZ", division: 7121 },
        // { nombre: "SK TENANCINGO", division: 7122 },
        // { nombre: "SK SAN BUENA VENTURA", division: 7125 },
        // { nombre: "SK CENTRO", division: 7126 },
        // { nombre: "SK LA ASUNCION", division: 7127 },
        // { nombre: "SK SANTIAGO TIANGUISTENCO", division: 7128 },
        // { nombre: "SK ATLACOMULCO", division: 7129 },
        // { nombre: "SK ZITACUARO", division: 7130 },
        // { nombre: "SK IXTLAHUACA", division: 7150 },
        // { nombre: "SKM ZITACUARO", division: 7305 },
        // { nombre: "SKM VALLE DE BRAVO AV TOLUCA", division: 7306 },
        // { nombre: "SKM SAN PEDRO", division: 7308 },
        // { nombre: "SKM SAN MATEO", division: 7309 },
        // { nombre: "SKM LEANDRO VALLE", division: 7319 },
        // { nombre: "SKM FIDEL VELAZQUEZ", division: 7323 },
        // { nombre: "SKM VALLE DE BRAVO", division: 7324 },
        // { nombre: "SKM XONACATLAN", division: 7331 },
        // { nombre: "SKM ALAMEDA", division: 7332 },
        // { nombre: "SKM GALERIAS", division: 7333 },
        // { nombre: "SKM METEPEC", division: 7334 },
        // { nombre: "SKM LERDO", division: 7335 },
        // { nombre: "SKM XINANTECATL", division: 7337 },
        // { nombre: "SKM SEMINARIO", division: 7338 },
        // { nombre: "SKM ATLACOMULCO", division: 7339 },
        // { nombre: "SKM SANTIAGO MILTEPEC", division: 7344 },
        // { nombre: "SKM TENANGO", division: 7346 },
        // { nombre: "SKM IXTLAHUACA", division: 7347 },
      ],
      listaSucursalesSurtitienda: [
      ...arraySucursales.sucursalesSurtitienda,
        // { division: 7511, nombre: "ST FIDEL VELAZQUEZ" },
        // { division: 7512, nombre: "ST TENANCINGO" },
        // { division: 7513, nombre: "ST CENTRAL DE ABASTOS D.F" },
        // { division: 7514, nombre: "ST CENTRAL DE ABASTOS TOLUCA" },
        // { division: 7515, nombre: "ST IXTLAHUACA" },
        // { division: 7517, nombre: "ST ZITACUARO" },
      ],
      listaSucursalesAcropolis: [
      ...arraySucursales.sucursalesAcropolis,
        // { division: 7702, nombre: "ACROPOLIS TOLUCA CENTRO" },
        // { division: 7703, nombre: "ACROPOLIS IXTLAHUACA" },
      ],
      listaSucursales: [],
      labelsGrafica: [],
      valoresGrafica: [],
      totalVerificaciones: 0,
      estadoPromotor: "Ambos",
      listaEstadoPromotor: [
        "Ambos",
        "NO",
        "SI"
      ],
    };
  },
  components: {
    // TablaLista,
    GraficaBarras,
  },
  created() {
    this.cargarFechasIniciales();
  },
  computed: {
    ...mapState("validacion", ["verificacionesFecha"]),
    mostrarFiltradoSucursales() {
      return this.tipoSucursal != 'General';
    },
    mostrarFiltradoEmpleado() {
      return this.sucursal != '';
    },
    mostrarFiltradoPromotor() {
      return this.sucursal != '';
    }
  },
  methods: {
    ...mapActions("validacion", [
      "obtenerTarjethabientesFechas",
    ]),
    filtrarVerificaciones() {
      
      this.conteoTipoSucursales = [];
      this.labelsGrafica = [];
      this.valoresGrafica = [];
      if ((this.sucursal != "" && this.empleado != "") || (this.estadoPromotor !== "Ambos")) {
        let verificacionesSucursalSeleccionada =
          this.verificacionesFecha.filter((verificacion) => {
            return verificacion.division == this.sucursal;
          });
        

        
        

        //aplicar el filtro de promotoria
        if (this.estadoPromotor === "SI") {
          // Filtrar solo las verificaciones con promotor "SI"
          verificacionesSucursalSeleccionada = verificacionesSucursalSeleccionada.filter(verificacion => verificacion.promotor === "true");
        } else if (this.estadoPromotor === "NO") {
          // Filtrar solo las verificaciones con promotor "NO"
          verificacionesSucursalSeleccionada = verificacionesSucursalSeleccionada.filter(verificacion => verificacion.promotor === "false");
        }

        verificacionesSucursalSeleccionada.forEach((sucursal) => {
          
          const fueEncontrada = this.conteoTipoSucursales.find(
            (sucursalConteo) => {
              return sucursalConteo.nombre == sucursal[this.empleado == "dioAlta" ? "dioalta" : "recomendo"];
            }
          );
          if (fueEncontrada) {
            const indiceSucursal =
              this.conteoTipoSucursales.indexOf(fueEncontrada);
            this.conteoTipoSucursales[indiceSucursal].conteo =
              this.conteoTipoSucursales[indiceSucursal].conteo + 1;
          } else {
            
            this.conteoTipoSucursales.push({
              nombre: sucursal[this.empleado == "dioAlta" ? "dioalta" : "recomendo"],
              conteo: 1,
            });
          }
        });

        const empleadoNullString = this.conteoTipoSucursales.find((sucursalConteo) => {
          return sucursalConteo.nombre == "NULL";
        });

        const empleadoNull = this.conteoTipoSucursales.find((sucursalConteo) => {
          return !sucursalConteo.nombre;
        });

        

        if (empleadoNullString) {
          if (empleadoNull) {
            const indiceEmpleado =
              this.conteoTipoSucursales.indexOf(empleadoNullString);
            this.conteoTipoSucursales[indiceEmpleado].nombre = "SIN RECOMENDACION"
            this.conteoTipoSucursales[indiceEmpleado].conteo += empleadoNull.conteo
            this.conteoTipoSucursales = this.conteoTipoSucursales.filter((elementoConteo) => {
              return elementoConteo != empleadoNull;
            })

          } else {
            const indiceEmpleado =
              this.conteoTipoSucursales.indexOf(empleadoNullString);
            this.conteoTipoSucursales[indiceEmpleado].nombre = "SIN RECOMENDACION"

          }
        }

        this.totalVerificaciones = verificacionesSucursalSeleccionada.length;

        
        this.conteoTipoSucursales.forEach((tipoSucursal) => {
          this.labelsGrafica.push(
            `${Math.round(
              (tipoSucursal.conteo /
                verificacionesSucursalSeleccionada.length) *
              100 *
              100
            ) / 100
            }% - ${tipoSucursal.conteo} - ${tipoSucursal.nombre}`
          );
          this.valoresGrafica.push(tipoSucursal.conteo);
        });
      } else {
        if (this.tipoSucursal == "General") {
          this.listaSucursales = [];
          this.verificacionesFecha.map((verificacion) => {
            this.listaTipoSucursalesPrefijo.forEach((sucursal) => {
              if (
                verificacion?.division.toString().substring(0, 2) ==
                sucursal.prefijo
              ) {
                const fueEncontrada = this.conteoTipoSucursales.find(
                  (sucursalConteo) => {
                    return sucursalConteo.nombre == sucursal.nombre;
                  }
                );
                if (fueEncontrada) {
                  const indiceSucursal =
                    this.conteoTipoSucursales.indexOf(fueEncontrada);
                  this.conteoTipoSucursales[indiceSucursal].conteo =
                    this.conteoTipoSucursales[indiceSucursal].conteo + 1;
                } else {
                  this.conteoTipoSucursales.push({
                    nombre: sucursal.nombre,
                    conteo: 1,
                  });
                }
              }
            });
          });
          let conteoConSucural = 0;
          this.conteoTipoSucursales.forEach((tipoSucursal) => {
            conteoConSucural += tipoSucursal.conteo;
          });
          if (this.verificacionesFecha.length - conteoConSucural > 0) {
            this.conteoTipoSucursales.push({
              nombre: "Sin sucursal",
              conteo: this.verificacionesFecha.length - conteoConSucural,
            });
          }

          this.conteoTipoSucursales.forEach((tipoSucursal) => {
            this.labelsGrafica.push(
              `${Math.round(
                (tipoSucursal.conteo / this.verificacionesFecha.length) *
                100 *
                100
              ) / 100
              }% - ${tipoSucursal.conteo} - ${tipoSucursal.nombre}`
            );
            this.valoresGrafica.push(tipoSucursal.conteo);
          });

          this.totalVerificaciones = this.verificacionesFecha.length;
        } else {
          
          
          let prefijoSeleccionado;
          let listaSucursalesSeleccionadas;
          switch (this.tipoSucursal) {
            case "Super Kompras":
              this.listaSucursales = this.listaSucursalesSk;
              prefijoSeleccionado = this.prefijoSuperKompras;
              listaSucursalesSeleccionadas = this.listaSucursalesSk;
              break;
            case "Surtitienda":
              this.listaSucursales = this.listaSucursalesSurtitienda;
              prefijoSeleccionado = this.prefijoSurtitienda;
              listaSucursalesSeleccionadas = this.listaSucursalesSurtitienda;
              break;
            case "Acropolis":
              this.listaSucursales = this.listaSucursalesAcropolis;
              prefijoSeleccionado = this.prefijoAcropolis;
              listaSucursalesSeleccionadas = this.listaSucursalesAcropolis;
              break;
          }

          const verificacionesSucursalSeleccionada =
            this.verificacionesFecha.filter((verificacion) => {
              if (this.tipoSucursal == "Super Kompras") {
                return (
                  verificacion?.division.toString().substring(0, 2) ==
                  prefijoSeleccionado ||
                  verificacion?.division.toString().substring(0, 2) ==
                  this.prefijoSuperKomprasMicro
                );
              } else {
                return (
                  verificacion?.division.toString().substring(0, 2) ==
                  prefijoSeleccionado
                );
              }
            });

          verificacionesSucursalSeleccionada.forEach((sucursal) => {
            listaSucursalesSeleccionadas.forEach((sucursalSeleccionada) => {
              if (sucursal.division == sucursalSeleccionada.division) {
                const fueEncontrada = this.conteoTipoSucursales.find(
                  (sucursalConteo) => {
                    return sucursalConteo.nombre == sucursalSeleccionada.nombre;
                  }
                );
                if (fueEncontrada) {
                  const indiceSucursal =
                    this.conteoTipoSucursales.indexOf(fueEncontrada);
                  this.conteoTipoSucursales[indiceSucursal].conteo =
                    this.conteoTipoSucursales[indiceSucursal].conteo + 1;
                } else {
                  this.conteoTipoSucursales.push({
                    nombre: sucursalSeleccionada.nombre,
                    conteo: 1,
                  });
                }
              }
            });
          });


          this.totalVerificaciones = verificacionesSucursalSeleccionada.length;

          this.conteoTipoSucursales.forEach((tipoSucursal) => {
            this.labelsGrafica.push(
              `${Math.round(
                (tipoSucursal.conteo /
                  verificacionesSucursalSeleccionada.length) *
                100 *
                100
              ) / 100
              }% - ${tipoSucursal.conteo} - ${tipoSucursal.nombre}`
            );
            this.valoresGrafica.push(tipoSucursal.conteo);
          });
        }
      }
    },
    cargarFechasIniciales() {
      const fechaActual = moment();
      const fechaActualSinFecha = fechaActual.format("YYYY-MM-DD");
      const fechaActualformato = moment(fechaActualSinFecha);
      const fechaActualTimestamp = fechaActualformato.format("x");
      const fecha7diasAnteriores =
        parseInt(fechaActualTimestamp) - 6 * 86400000;
      const fechaActualDia =
        parseInt(fechaActualTimestamp) + 23 * 3600000 + 59 * 60000;
      const fechaInicio = moment(fecha7diasAnteriores).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      const fechaFin = moment(fechaActualDia).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      this.fechaFiltradoTarjetahabientes = [fechaInicio, fechaFin];
      


    },

  },
  watch: {
    verificacionesFecha() {
      this.filtrarVerificaciones();
    },
    estadoPromotor() {
      this.filtrarVerificaciones();
    },
    tipoSucursal() {
      this.filtrarVerificaciones();
      this.sucursal = ""
      this.empleado = ""
    },
    sucursal() {

      if (this.sucursal != "") {
        this.empleado = "dioAlta";
      }
      this.filtrarVerificaciones();
    },
    empleado() {
      this.filtrarVerificaciones();
    },
    tarjetasVerificadas(nuevasTarjetas) {
      
      
      this.tarjetasConsultados = [];

      if (nuevasTarjetas) {
        
        for (const [id, tarjeta] of Object.entries(nuevasTarjetas)) {
          this.tarjetasIniciales.push(tarjeta);
          const objetoPreregistro = {
            tarjeta: tarjeta["tarjeta"],
            id_cli: tarjeta["id_cli"],
            nombre: tarjeta["nombre"],
            apellidoP: tarjeta["apellidoP"],
            apellidoM: tarjeta["apellidoM"],
            email: tarjeta["email"],
            telefono: tarjeta["telefono"],
            fechanacim: tarjeta["fechanacim"],
            cp: tarjeta["cp"],
            sexo: tarjeta["sexo"],
            recomendo: tarjeta["recomendo"] ? tarjeta["recomendo"] : "-",
            division: tarjeta["division"],
            dioalta: tarjeta["dioalta"],
            fechaaltat: tarjeta["fechaaltat"],
            fechaverif: tarjeta["fechaverif"],
            preregistro: tarjeta["preregistro"] ? tarjeta["preregistro"] : "-",
            promotor: tarjeta["promotor"],
            verificoEmail: tarjeta["verificoEmail"],
          };
          this.tarjetasConsultados.push(objetoPreregistro);
        }
        
        this.tarjetasFiltradas = this.tarjetasConsultados;
      } else {
        
        this.tarjetasConsultados = [];
        this.tarjetasFiltradas = this.tarjetasConsultados;
      }
      this.filtrarTarjetas();
    },
    async fechaFiltradoTarjetahabientes(fechas) {
      const tarjetahabientes = await this.obtenerTarjethabientesFechas(fechas);
      
    },
  },
};
</script>
<template>
  <Breadcrumbs title="Graficas de altas y verificaciones" />
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <label>Seleccione un formato:</label>
            <select class="form-select digits" id="exampleFormControlSelect30" v-model="tipoSucursal">
              <option v-for="tipo in listaTipoSucursales">{{ tipo }}</option>
            </select>
          </div>
          <div class="col-lg-4 col-0">
          <div v-if="mostrarFiltradoPromotor">
            <label>Promotoria:</label>
            <select class="form-select digits" id="exampleFormControlPromotor" v-model="estadoPromotor">
              <option v-for="valor in listaEstadoPromotor">{{ valor }}</option>
            </select>
          </div>
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por fecha:</label>
            <datepicker class="datepicker-here digits" range id="fechaPedidos" :enable-time-picker="false" type="date"
              locale="es" format="dd/MM/yyyy" select-text="Confirmar" cancel-text="Cancelar"
              v-model="fechaFiltradoTarjetahabientes" />
          </div>
        </div>
        <div class="row" v-if="mostrarFiltradoSucursales">
          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por sucursal:</label>
            <select class="form-select digits" id="exampleFormControlSelect30" v-model="sucursal">
              <option v-for="sucursal in listaSucursales" :value="sucursal.division">
                {{ sucursal.nombre }}
              </option>
            </select>
          </div>
          <div class="col-lg-4 col-0"></div>
          <div class="col-lg-4 col-12 mb-3" v-if="mostrarFiltradoEmpleado">
            <label>Filtrado por empleado:</label>
            <div class="col">
              <div class="mb-3 m-t-15 custom-radio-ml">
                <div class="form-check radio radio-primary d-inline-block me-2">
                  <input class="form-check-input" id="dioAlta" type="radio" name="empleado" value="dioAlta"
                    v-model="empleado" />
                  <label class="form-check-label" for="dioAlta">Dio alta</label>
                </div>
                <div class="form-check radio radio-primary d-inline-block ">
                  <input class="form-check-input" id="recomendo" type="radio" name="empleado" value="recomendo"
                    v-model="empleado" />
                  <label class="form-check-label" for="recomendo">Recomendo</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GraficaBarras :labels="labelsGrafica" :series="valoresGrafica" />
      <h5 class="ms-3 mb-3">Total verificaciones: {{ totalVerificaciones }}</h5>
      <!-- <pre>
        {{ conteoTipoSucursales }}
      </pre>
      <pre>
        {{ verificacionesFecha }}
      </pre> -->
    </div>
  </div>
</template>
