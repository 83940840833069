<script>
import { mapActions } from "vuex";

// import {ref} from 'vue';
// import {useValidacionStore} from '../stores/validacionStore.js';
// import {useRouter} from 'vue-router';

export default {
  props: ["urlSiguiente"],
  data() {
    return {
      tarjeta: "",
      botonDesactivado: false,
    };
  },
  async created() {
    
  },
  methods: {
    ...mapActions("validacion", ["buscarTarjeta", "buscarTarjetaCliente"]),
    async consultarTarjeta() {
      try {
        let tarjetaEncontrada;
        this.botonDesactivado = true;
        if (this.$router.currentRoute.value.meta.authRequired) {
          tarjetaEncontrada = await this.buscarTarjeta(this.tarjeta);
        } else {
          tarjetaEncontrada = await this.buscarTarjetaCliente(this.tarjeta);
        }
        if (tarjetaEncontrada) {
          this.botonDesactivado = false;
          this.$router.push(this.urlSiguiente);
        }
      } catch (error) {
        
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
        this.botonDesactivado = false;
      }
    },
  },
};

// const props = defineProps(["urlSiguiente"]);

// const {buscarTarjeta} = useValidacionStore();

// const router = useRouter();
// const tarjeta = ref('');
// const botonDesactivado = ref(false);

// const consultarTarjeta = async () => {
//     try {
//         botonDesactivado.value = true;
//         const tarjetaEncontrada = await buscarTarjeta(tarjeta.value);
//         if (tarjetaEncontrada) {
//             botonDesactivado.value = false;
//             router.push(props.urlSiguiente);
//         }
//     } catch (error) {
//         
//         Swal.fire({
//             type: 'error',
//             title: 'Oops...',
//             text: error
//         })
//         botonDesactivado.value = false;
//     }
// };
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Buscar tarjeta</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-2">
      <div class="card-body">
        <form @submit.prevent="consultarTarjeta()">
          <div
            class="d-flex align-items-center justify-content-center"
            style="height: 25rem"
          >
            <div class="w-100">
              <h6 class="col-form-label">Tarjeta</h6>
              <input
                class="form-control"
                type="number"
                placeholder="Ingresa tu tarjeta"
                v-model.trim="tarjeta"
              />
            </div>
          </div>
          <div class="mt-auto text-end">
            <button class="btn btn-primary" :disabled="botonDesactivado"  type="submit">
              <div
                class="loader-box m-0"
                style="height: 20px"
                v-if="botonDesactivado"
              >
                <div
                  class="loader-2 me-2"
                  style="height: 25px !important; width: 25px !important"
                ></div>
                <span>buscando...</span>
              </div>
              <span v-else>Buscar tarjeta</span>
            </button>
            <!-- <button :disabled="botonDesactivado" class="btn btn-primary">
              Buscar tarjeta
            </button> -->
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- <div class="auth-wrapper d-flex justify-content-center align-items-center">
        <div class="card tarjeta-buscar w-50">
            <div class="card-body d-flex align-items-center">
                <div class="w-100 mx-2">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Ingresa tu número de tarjeta club</label>
                        <input type="text" class="form-control" id="numeroTarjetaClub" aria-describedby="tarjetaClub"
                            placeholder="Tarjeta club" v-model.trim="tarjeta" />
                    </div>
                    <button disabled="botonDesactivado" type="button" class="btn btn-info waves-effect waves-light px-5"
                        @click="consultarTarjeta()">Buscar</button>
                </div>
            </div>
        </div>
    </div> -->
</template>
<style></style>
