<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import * as XLSX from "xlsx";
import { PDFDocument, rgb } from "pdf-lib";
import TablaLista from "../../../components/tablaLista.vue";
import TarjetaResumenPedidos from "../../../components/tarjetaResumenPedidos.vue";
export default {
  data() {
    return {
      pedido: {},
      infoPedido: [],
      infoCliente: {},
      infoPago: {},
      productos: [],
      fechaFiltradoPedidos: [],
      headersTabla: [
        "Folio",
        "Sucursal",
        "Cliente",
        "Dirección Entrega",
        "Fecha y hora",
        "Total",
        "Estatus",
        "Acciones",
        "Reporte",
      ],
      campos: [
        { tipo: "texto", clave: "id" },
        { tipo: "texto", clave: "sucursal" },
        { tipo: "texto", clave: "cliente" },
        { tipo: "texto", clave: "direccionEntrega" },
        { tipo: "texto", clave: "fechaHora" },
        { tipo: "texto", clave: "total" },
        { tipo: "estatus", clave: "estatus" },
        { tipo: "boton", funcion: "detalle" },
        { tipo: "boton", funcion: "descarga" },
      ],
      listaEstatus: [
        { estatus: "Solicitud", color: "#F97316" },
        { estatus: "En proceso", color: "#3B82F6" },
        { estatus: "Cancelado", color: "#E11D48" },
        { estatus: "Concluido", color: "#10B981" },
        { estatus: "En ruta", color: "#A21CAF" },
      ],
      cantidadPedidosEstatus: {
        solicitud: 0,
        enProceso: 0,
        cancelado: 0,
        concluido: 0,
        enRuta: 0,
      },
      totalPedidosEstatus: {
        solicitud: 0.0,
        enProceso: 0,
        cancelado: 0,
        concluido: 0,
        enRuta: 0,
      },
      pedidosConsultados: [],
      totalPedidosConsultados: 0,
      montoTotalPedidosConsultados: 0,
      arregloEstatus: [
        "Solicitud",
        "En proceso",
        "Concluido",
        "Cancelado",
        "En ruta",
        "Todos los estatus",
      ],
      estatusSeleccionado: "Todos los estatus",
      tiendaSeleccionada: 0,
      sucursalesConsultadas: [],
      pedidosFiltrados: [],
    };
  },
  components: {
    TablaLista,
    TarjetaResumenPedidos,
  },
  created() {
    this.cargarFechasIniciales();
    this.consultarSucursalesComercio();
    // 
  },
  computed: {
    ...mapGetters("autenticacion", ["getUsuarioActual"]),
    ...mapState("comercio", ["pedidos", "sucursalesComercio"]),
    montoTotalPedidosConsultadosRedondeado() {
      return Math.round(this.montoTotalPedidosConsultados * 100) / 100;
    },
  },
  methods: {
    ...mapActions("comercio", [
      "consultarPedidos",
      "consultarSucursalesComercio",
    ]),
    cargarFechasIniciales() {
      const fechaActual = moment();
      const fechaActualSinFecha = fechaActual.format("YYYY-MM-DD");

      const fechaActualformato = moment(fechaActualSinFecha);
      const fechaActualTimestamp = fechaActualformato.format("x");
      const fecha7diasAnteriores =
        parseInt(fechaActualTimestamp) - 6 * 86400000;
      const fechaActualDia =
        parseInt(fechaActualTimestamp) + 11 * 3600000 + 59 * 60000;

      const fechaInicio = moment(fecha7diasAnteriores).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      const fechaFin = moment(fechaActualDia).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );

      
      this.fechaFiltradoPedidos = [fechaInicio, fechaFin];
      // this.consultarPedidos(this.fechaFiltradoPedidos);
    },
    async verDetallePedido(elemento) {
      let pedidoSeleccionado = this.pedidos[elemento.id];
      pedidoSeleccionado["id"] = elemento.id;

      localStorage.setItem("detallePedido", JSON.stringify(pedidoSeleccionado));

      this.$router.push(`/detalle-pedido/${elemento.id}`);

      // this.$swal({
      //   title: "¡Producto agregado con éxito!",
      //   text: `${elemento?.nombre}`,
      //   icon: "success",
      //   confirmButtonText: "Continuar",
      //   confirmButtonColor: "#10B981"
      // })
    },
    dividirTextoEnLineas(texto, maxCharsPorLinea) {
      const lineas = [];
      let inicio = 0;

      while (inicio < texto.length) {
        lineas.push(texto.slice(inicio, inicio + maxCharsPorLinea));
        inicio += maxCharsPorLinea;
      }

      return lineas;
    },
    async descargarElemento(elemento) {
      const pedidoSeleccionado = this.pedidos[elemento.id];
      
      if (!pedidoSeleccionado) {
        console.error("Pedido no encontrado");
        return;
      }
      const pdfDoc = await PDFDocument.create();
      let page = pdfDoc.addPage([600, 800]); // Añadir una página con tamaño específico
      const { height } = page.getSize();

      const maxCharsPorLinea = 65; // Número máximo de caracteres por línea
      // Información del pedido
      page.drawText(`Folio: ${pedidoSeleccionado.Folio}`, { x: 50, y: height - 50, size: 20, color: rgb(0, 0.53, 0.71) });
      page.drawText(`Cliente: ${pedidoSeleccionado["Cliente name"]}`, { x: 50, y: height - 100, size: 14 });
      page.drawText(`Teléfono: ${pedidoSeleccionado["Cliente telefono"]}`, { x: 50, y: height - 140, size: 14 });

      let posicionY = height - 180;

      // Dirección del cliente
      const lineasDireccion = this.dividirTextoEnLineas(`Dirección: ${pedidoSeleccionado.Direccion}`, maxCharsPorLinea);
      lineasDireccion.forEach((linea) => {
        if (posicionY < 50) {
          page = pdfDoc.addPage([600, 800]);
          posicionY = height - 50;
        }
        page.drawText(linea, { x: 50, y: posicionY, size: 14 });
        posicionY -= 20;
      });

      let posicionTablaY = posicionY - 30;

      // Encabezado de la tabla de productos
      page.drawText('Código', { x: 50, y: posicionTablaY, size: 12, color: rgb(0, 0, 0) });
      page.drawText('Nombre', { x: 170, y: posicionTablaY, size: 12, color: rgb(0, 0, 0) });
      page.drawText('Cantidad', { x: 440, y: posicionTablaY, size: 12, color: rgb(0, 0, 0) });
      page.drawText('Precio', { x: 500, y: posicionTablaY, size: 12, color: rgb(0, 0, 0) });

      page.drawLine({
        start: { x: 50, y: posicionTablaY - 10 },
        end: { x: 550, y: posicionTablaY - 10 },
        thickness: 1,
        color: rgb(0, 0, 0)
      });

      // Detalles de los productos
      Object.keys(pedidoSeleccionado.Productos).forEach((codigo) => {
        const producto = pedidoSeleccionado.Productos[codigo];
        posicionTablaY -= 30;

        if (posicionTablaY < 50) {
          page = pdfDoc.addPage([600, 800]);
          posicionTablaY = height - 50;
        }

        page.drawText(`${codigo}`, { x: 50, y: posicionTablaY, size: 10 });
        page.drawText(`${producto.Nombre}`, { x: 170, y: posicionTablaY, size: 10 });
        page.drawText(`${producto.Cantidad}`, { x: 440, y: posicionTablaY, size: 10 });
        page.drawText(`$${producto.Total}`, { x: 500, y: posicionTablaY, size: 10 });
      });

      // Subtotal, envío y total
      page.drawText(`Subtotal: $${pedidoSeleccionado.Subtotal}`, { x: 50, y: posicionTablaY - 40, size: 14 });
      page.drawText(`Envío: $${pedidoSeleccionado.Envio}`, { x: 50, y: posicionTablaY - 60, size: 14 });
      page.drawText(`Total: $${pedidoSeleccionado.Subtotal + Number(pedidoSeleccionado.Envio)}`, { x: 50, y: posicionTablaY - 80, size: 14 });

      // Guardar el PDF
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `detalle_${pedidoSeleccionado.Folio}.pdf`;
      link.click();
    },

    exportarExcel() {
      try {
        const dataPedidos = [];
        for (const [id, pedido] of Object.entries(this.pedidos)) {
          dataPedidos.push([
            pedido["Folio"],
            pedido["sucursal"] ? pedido["sucursal"] : "null",
            pedido["Division"],
            pedido["Cliente name"],
            pedido["Cliente id"],
            pedido["Direccion"],
            pedido["TimeSolicitud"]
              ? moment(pedido["TimeSolicitud"]).format("DD/MM/YYYY, h:mm A")
              : "null",
            pedido["TimeEnProceso"]
              ? moment(pedido["TimeEnProceso"]).format("DD/MM/YYYY, h:mm A")
              : "null",
            pedido["TimeEnRuta"]
              ? moment(pedido["TimeEnRuta"]).format("DD/MM/YYYY, h:mm A")
              : "null",
            pedido["TimeConcluido"]
              ? moment(pedido["TimeConcluido"]).format("DD/MM/YYYY, h:mm A")
              : "null",
            pedido["Pago"],
            pedido["Subtotal"],
            pedido["Envio"],
            pedido["Total"],
            pedido["TarjetaClubCodigo"] ? pedido["TarjetaClubCodigo"] : "null",
            pedido["recogerTienda"] ? pedido["recogerTienda"] : "FALSO",
            pedido["Status"],
          ]);
        }

        
        const ws = XLSX.utils.aoa_to_sheet([
          [
            "folio",
            "sucursal",
            "divison",
            "cliente",
            "idCliente",
            "direccionEntrega",
            "fechaHoraSolicitud",
            "fechaHoraEnProceso",
            "fechaHoraEnRuta",
            "fechaHoraConcluido",
            "metodoPago",
            "subtotal",
            "costoEnvio",
            "total",
            "tarjetaClub",
            "recogerTienda",
            "status",
          ],
          ...dataPedidos,
        ]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "DATOS MAESTROS");
        const fileName = `Reporte_Pedidos_${moment(
          this.fechaFiltradoPedidos[0]
        ).format("DD-MM-YYYY")}_${moment(this.fechaFiltradoPedidos[1]).format(
          "DD-MM-YYYY"
        )}.xlsx`;

        XLSX.writeFile(wb, fileName);
      } catch (error) {
        console.error("Error al exportar a Excel:", error);
      }
    },
    filtrarPedidosSucursalEstatus() {
      this.pedidosFiltrados = this.pedidosConsultados;
      this.totalPedidosConsultados = 0;
      this.montoTotalPedidosConsultados = 0;
      this.cantidadPedidosEstatus = {
        solicitud: 0,
        enProceso: 0,
        cancelado: 0,
        concluido: 0,
        enRuta: 0,
      };
      this.totalPedidosEstatus = {
        solicitud: 0,
        enProceso: 0,
        cancelado: 0,
        concluido: 0,
        enRuta: 0,
      };

      //filtrado estatus

      if (this.estatusSeleccionado != "Todos los estatus") {
        this.pedidosFiltrados = this.pedidosFiltrados.filter((pedido) => {
          return pedido.estatus == this.estatusSeleccionado;
        });
      }

      if (this.tiendaSeleccionada != 0) {
        this.pedidosFiltrados = this.pedidosFiltrados.filter((pedido) => {
          return pedido.division == this.tiendaSeleccionada;
        });
      }

      this.totalPedidosConsultados = this.pedidosFiltrados.length;

      

      this.pedidosFiltrados.forEach((pedido) => {
        if (pedido.total) {
          this.montoTotalPedidosConsultados += pedido.total;
        }
        // 
        switch (pedido.estatus) {
          case "Solicitud":
            this.cantidadPedidosEstatus.solicitud++;
            if (pedido.total) {
              this.totalPedidosEstatus.solicitud += pedido.total;
            }

            break;
          case "En proceso":
            this.cantidadPedidosEstatus.enProceso++;
            if (pedido.total) {
              this.totalPedidosEstatus.enProceso += pedido.total;
            }
            break;
          case "Concluido":
            // 
            this.cantidadPedidosEstatus.concluido++;
            if (pedido.total) {
              this.totalPedidosEstatus.concluido += pedido.total;
            }
            // 
            break;
          case "Cancelado":
            this.cantidadPedidosEstatus.cancelado++;
            if (pedido.total) {
              this.totalPedidosEstatus.cancelado += pedido.total;
            }
            break;
          case "En ruta":
            this.cantidadPedidosEstatus.enRuta++;
            if (pedido.total) {
              this.totalPedidosEstatus.enRuta += pedido.total;
            }
            break;
        }
      });
      
    },
  },
  watch: {
    sucursalesComercio(nuevasSucursales) {
      this.sucursalesConsultadas = [];
      for (const [id, sucursal] of Object.entries(nuevasSucursales)) {
        const objetoSucursal = {
          nombre: sucursal["sucursal"],
          numeroDivision: sucursal["division"],
        };
        this.sucursalesConsultadas.push(objetoSucursal);
      }
      
    },
    pedidos(nuevosPedidos) {
      this.totalPedidosConsultados = 0;
      this.montoTotalPedidosConsultados = 0;
      this.pedidosConsultados = [];
      this.cantidadPedidosEstatus = {
        solicitud: 0,
        enProceso: 0,
        cancelado: 0,
        concluido: 0,
        enRuta: 0,
      };
      this.totalPedidosEstatus = {
        solicitud: 0,
        enProceso: 0,
        cancelado: 0,
        concluido: 0,
        enRuta: 0,
      };
      if (nuevosPedidos) {
        for (const [id, pedido] of Object.entries(nuevosPedidos)) {
          const fechaSegundos = pedido["TimeSolicitud"] / 1000;
          const fechaFormato = moment
            .unix(fechaSegundos)
            .format("DD/MM/YYYY, h:mm A");
          const objetoPedido = {
            id: pedido["Folio"],
            sucursal: pedido["sucursal"],
            cliente: pedido["Cliente name"],
            direccionEntrega: pedido["Direccion"],
            fechaHora: fechaFormato,
            fechaHoraTimestamp: pedido["TimeSolicitud"],
            estatus: pedido["Status"],
            total: pedido["Total"],
            division: pedido["Division"],
          };
          this.pedidosConsultados.push(objetoPedido);
        }
        this.pedidosConsultados = this.pedidosConsultados.sort((a, b) => {
          
            return b.fechaHoraTimestamp - a.fechaHoraTimestamp;
          
        });

        this.filtrarPedidosSucursalEstatus();
      }
    },
    fechaFiltradoPedidos(fechas) {
      if(this.getUsuarioActual.rolApp == "Sucursal"){
        


        const fechasDivision = [ fechas[0], fechas[1], this.getUsuarioActual.division ];
        this.consultarPedidos(fechasDivision);
      } else {

        this.consultarPedidos(fechas);
      }
    },
  },
};
</script>
<template>
  <Breadcrumbs title="Pedidos" />
  <div class="container-fluid dashboard-2">
    <div class="row">
      <div class="col-md-12 col-lg-4 mb-3">
        <h5>Total de pedidos en el periodo: {{ totalPedidosConsultados }}</h5>
      </div>
      <div class="col-md-12 col-lg-8 mb-3">
        <h5>
          Monto total de compra en el periodo:
          {{ montoTotalPedidosConsultadosRedondeado }} MXN
        </h5>
      </div>
    </div>
    <div class="d-flex flex-xl-row flex-column justify-content-between">
      <TarjetaResumenPedidos
        :cantidadPedidos="cantidadPedidosEstatus.solicitud"
        :total="totalPedidosEstatus.solicitud"
        colorEstatus="#F97316"
        estatus="Solicitud"
      ></TarjetaResumenPedidos>
      <TarjetaResumenPedidos
        :cantidadPedidos="cantidadPedidosEstatus.enProceso"
        :total="totalPedidosEstatus.enProceso"
        colorEstatus="#3B82F6"
        estatus="En proceso"
      ></TarjetaResumenPedidos>
      <TarjetaResumenPedidos
        :cantidadPedidos="cantidadPedidosEstatus.enRuta"
        :total="totalPedidosEstatus.enRuta"
        colorEstatus="#A21CAF"
        estatus="En ruta"
      ></TarjetaResumenPedidos>
      <TarjetaResumenPedidos
        :cantidadPedidos="cantidadPedidosEstatus.concluido"
        :total="totalPedidosEstatus.concluido"
        colorEstatus="#10B981"
        estatus="Concluido"
      ></TarjetaResumenPedidos>
      <TarjetaResumenPedidos
        :cantidadPedidos="cantidadPedidosEstatus.cancelado"
        :total="totalPedidosEstatus.cancelado"
        colorEstatus="#E11D48"
        estatus="Cancelado"
      ></TarjetaResumenPedidos>
    </div>
  </div>

  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="contenedor-sucursal" v-if="getUsuarioActual.rolApp == 'Sucursal'">
          <div class="row mb-3">
            <div
              class="col-lg-4 col-12 d-flex align-items-end justify-content-center justify-content-lg-start mb-3 mb-lg-2">
              <button class="btn btn-outline-success d-block" type="button" @click="exportarExcel()">
                Descargar Excel
              </button>
            </div>
            <div class="col-lg-4 col-12 mb-3 mb-lg-2">
              <label>Filtrar por estatus:</label>
              <select class="form-select" id="estatusSeleccionado" aria-describedby="estatusSeleccionado"
                v-model.trim="estatusSeleccionado" @change="filtrarPedidosSucursalEstatus()">
                <option v-for="estatus in arregloEstatus">{{ estatus }}</option>
              </select>
            </div>
            <div class="col-lg-4 col-12 mb-3 mb-lg-2">
              <label>Filtrado por fecha:</label>
              <datepicker class="datepicker-here digits" range id="fechaPedidos" :enable-time-picker="false" type="date"
                locale="es" format="dd/MM/yyyy" select-text="Confirmar" cancel-text="Cancelar"
                v-model="fechaFiltradoPedidos" />
            </div>
          </div>
        </div>
        <div class="contenedor-administrador" v-if="getUsuarioActual.rolApp == 'Administrador'">
          <div class="row mb-3">
            <div class="col-lg-4 col-12 mb-3 mb-lg-2">
              <label>Filtrar por tienda:</label>
              <select class="form-select" id="tiendaSeleccionada" aria-describedby="tiendaSeleccionada"
                v-model.trim="tiendaSeleccionada" @change="filtrarPedidosSucursalEstatus()">
                <option v-for="sucursal in sucursalesConsultadas" :value="sucursal.numeroDivision">
                  {{ sucursal.nombre }}
                </option>
              </select>
            </div>
            <div class="col-lg-4 col-12 mb-3 mb-lg-2">
              <label>Filtrar por estatus:</label>
              <select class="form-select" id="estatusSeleccionado" aria-describedby="estatusSeleccionado"
                v-model.trim="estatusSeleccionado" @change="filtrarPedidosSucursalEstatus()">
                <option v-for="estatus in arregloEstatus">{{ estatus }}</option>
              </select>
            </div>
            <div class="col-lg-4 col-12 mb-3 mb-lg-2">
              <label>Filtrado por fecha:</label>
              <datepicker class="datepicker-here digits" range id="fechaPedidos" :enable-time-picker="false" type="date"
                locale="es" format="dd/MM/yyyy" select-text="Confirmar" cancel-text="Cancelar"
                v-model="fechaFiltradoPedidos" />
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-4 col-12 d-flex align-items-end justify-content-center justify-content-lg-start mb-3 mb-lg-2">
              <button class="btn btn-outline-success d-block" type="button" @click="exportarExcel()">
                Descargar Excel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <TablaLista :data="pedidosFiltrados" :headers="headersTabla" :search="false" :campos="campos"
          :listaEstatus="listaEstatus" @verDetalleElemento="verDetallePedido" @descargarElemento="descargarElemento">
        </TablaLista>
      </div>
    </div>
  </div>
</template>
