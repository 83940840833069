<template>
  <div class="row">
    <div class="col-md-2 col-0"></div>
    <div class="col-md-8 col-12">
      <div id="piechart">
        <apexchart
          type="bar"
          :options="chartOptions"
          :series="series"
          :labels="labels"
          ref="chart"
        ></apexchart>
      </div>
    </div>
  </div>
  <div class="col-lg-2 col-0"></div>
</template>
<script>
var primary = localStorage.getItem("primary_color") || "#5c61f2";
var secondary = localStorage.getItem("secondary_color") || "#eeb82f";
var success = localStorage.getItem("success_color") || "#61ae41";
var info = localStorage.getItem("info_color") || "#4faad5";
export default {
  props: ["labels", "series"],
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            name: "Tarjetas verificadas",
            data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
          },
        ],
        xaxis: {
          categories: [],
        },
        colors: [primary],
        labels: {
          rotate: -45, // Rotación de las etiquetas para mostrar más caracteres
          rotateAlways: true,
        },
      },
      // chartOptions: {
      //   chart: {
      //     width: 350,
      //     height: 350,
      //     type: "bar",
      //   },
      //   labels: [],
      //   series: [],
      //   responsive: [
      //     {
      //       breakpoint: undefined,
      //       options: {
      //         chart: {
      //           width: 200,
      //         },
      //         legend: {
      //           position: "bottom",
      //         },
      //       },
      //     },
      //   ],
      //   colors: [primary, secondary, success, "#a927f9", info],
      // },
    };
  },
  watch: {
    labels(newLabels) {
      
      this.$refs.chart.updateOptions({
        xaxis: {
          categories: newLabels,
        },
      });
    },
    series(newSeries) {
      
      this.$refs.chart.updateOptions({
        series: [
          {
            name: "Tarjetas verificadas",
            data: newSeries,
          },
        ],
      });
    },
  },
};
</script>
