<template>
  <tapTop />
  <div class="page-wrapper" :class="layoutobj">
    <div class="page-header" :class="{ close_icon: !togglesidebar }">
      <Header @clicked="sidebar_toggle" />
    </div>
    <div class="page-body-wrapper">
      <div
        class="sidebar-wrapper"
        :class="[{ close_icon: !togglesidebar }]"
        :sidebar-layout="layout.settings.sidebar_setting"
      >
        <Sidebar @clicked="sidebar_toggle" />
      </div>
      <div class="page-body">
        <transition name="fadeIn" enter-active-class="animated fadeIn">
          <router-view></router-view>
        </transition>
      </div>
      <footerView />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { layoutClasses } from "../constants/layout";
import tapTop from "./tapTop.vue";

import Header from "./header/indexView.vue";
import Sidebar from "./sidebar/indexView.vue";
import footerView from "./footerView.vue";
import moment from "moment";
import { arraySucursales } from "@/constants/sucursales";
// import customizer from "./customizer/indexView.vue"

export default {
  components: {
    // eslint-disable-next-line
    Header,
    Sidebar,
    footerView,
    tapTop,
    //   customizer
  },
  data() {
    return {
      usuario: "",
      tipo: "",
      listaSucursalesSk: [
        ...arraySucursales.sucursalesSk
        // { nombre: "SK LAS MARINAS", division: 7104 },
        // { nombre: "SK TOLLOCAN", division: 7120 },
        // { nombre: "SK PINO SUAREZ", division: 7121 },
        // { nombre: "SK TENANCINGO", division: 7122 },
        // { nombre: "SK SAN BUENA VENTURA", division: 7125 },
        // { nombre: "SK CENTRO", division: 7126 },
        // { nombre: "SK LA ASUNCION", division: 7127 },
        // { nombre: "SK SANTIAGO TIANGUISTENCO", division: 7128 },
        // { nombre: "SK ATLACOMULCO", division: 7129 },
        // { nombre: "SK ZITACUARO", division: 7130 },
        // { nombre: "SK IXTLAHUACA", division: 7150 },
      ],
      listaSucursalesSkMicro: [
      ...arraySucursales.sucursalesSkMicro
        // { division: 7305, nombre: "SKM ZITACUARO" },
        // { division: 7306, nombre: "SKM VALLE DE BRAVO AV TOLUCA" },
        // { division: 7308, nombre: "SKM SAN PEDRO" },
        // { division: 7309, nombre: "SKM SAN MATEO" },
        // { division: 7319, nombre: "SKM LEANDRO VALLE" },
        // { division: 7323, nombre: "SKM FIDEL VELAZQUEZ" },
        // { division: 7324, nombre: "SKM VALLE DE BRAVO" },
        // { division: 7331, nombre: "SKM XONACATLAN" },
        // { division: 7332, nombre: "SKM ALAMEDA" },
        // { division: 7333, nombre: "SKM GALERIAS" },
        // { division: 7334, nombre: "SKM METEPEC" },
        // { division: 7335, nombre: "SKM LERDO" },
        // { division: 7337, nombre: "SKM XINANTECATL" },
        // { division: 7338, nombre: "SKM SEMINARIO" },
        // { division: 7339, nombre: "SKM ATLACOMULCO" },
        // { division: 7344, nombre: "SKM SANTIAGO MILTEPEC" },
        // { division: 7346, nombre: "SKM TENANGO" },
        // { division: 7347, nombre: "SKM IXTLAHUACA" },
      ],
      listaSucursalesSurtitienda: [
        ...arraySucursales.sucursalesSurtitienda
        // { division: 7511, nombre: "ST FIDEL VELAZQUEZ" },
        // { division: 7512, nombre: "ST TENANCINGO" },
        // { division: 7513, nombre: "ST CENTRAL DE ABASTOS D.F" },
        // { division: 7514, nombre: "ST CENTRAL DE ABASTOS TOLUCA" },
        // { division: 7515, nombre: "ST IXTLAHUACA" },
        // { division: 7517, nombre: "ST ZITACUARO" },
      ],
      listaSucursalesAcropolis: [
        ...arraySucursales.sucursalesAcropolis
        // { division: 7702, nombre: "ACROPOLIS TOLUCA CENTRO" },
        // { division: 7703, nombre: "ACROPOLIS IXTLAHUACA" },
      ],
      listaSucursales: [],
      listaEmpleados: [],
      contraseña: "",
      nombre: "",
      division: "",
      mobileheader_toggle_var: false,
      sidebar_toggle_var: false,
      horizontal_Sidebar: true,
      resized: false,
      layoutobj: {},
    };
  },
  computed: {
    ...mapGetters("autenticacion", ["getUsuarioActual"]),
    ...mapState("autenticacion", ["usuarioActual"]),
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      togglesidebar: (state) => state.menu.togglesidebar,
      activeoverlay: (state) => state.menu.activeoverlay,
    }),
    layoutobject: {
      get: function () {
        return JSON.parse(
          JSON.stringify(
            layoutClasses.find(
              (item) => Object.keys(item).pop() === this.layout.settings.layout
            )
          )
        )[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find(
          (item) => Object.keys(item).pop() === this.layout.settings.layout
        );
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
        return this.layoutobj;
      },
    },
  },
  watch: {
    $route() {
      this.menuItems.filter((items) => {
        if (items.path === this.$route.path)
          this.$store.dispatch("menu/setActiveRoute", items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch("menu/setActiveRoute", subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch("menu/setActiveRoute", subSubItems);
          });
        });
      });
      this.layoutobj = layoutClasses.find(
        (item) => Object.keys(item).pop() === this.layout.settings.layout
      );
      if (
        window.innerWidth < 991 &&
        this.layout.settings.layout === "Horizontal"
      ) {
        const newlayout = JSON.parse(
          JSON.stringify(this.layoutobj).replace(
            "horizontal-wrapper",
            "compact-wrapper"
          )
        );
        this.layoutobj = JSON.parse(JSON.stringify(newlayout))[
          this.layout.settings.layout
        ];
      } else {
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
      }
    },
    sidebar_toggle_var: function () {
      this.resized =
        this.width <= 991 ? !this.sidebar_toggle_var : this.sidebar_toggle_var;
    },
  },
  created() {
    if (this.usuarioActual && this.usuarioActual.rol == "sucursal") {
      
      if (!this.existeEmpleado()) {
        this.MostrarAlertaUsuario();
      }
    } else if (this.usuarioActual && this.usuarioActual.rol == "promotor") {
      if (!this.existeEmpleado()) {
        this.MostrarAlertaPromotor();
      }
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.resized = this.sidebar_toggle_var;
    this.$store.dispatch("layout/set");
    // this.$router.replace({ 'query': null }).catch(err => err);
    this.layout.settings.layout = this.$route.query.layout
      ? this.$route.query.layout
      : "default";
    this.layoutobj = layoutClasses.find(
      (item) => Object.keys(item).pop() === this.layout.settings.layout
    );
    this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
      this.layout.settings.layout
    ];
  },
  updated() {
    
  },
  methods: {
    ...mapActions("autenticacion", [
      "consultarAccesosEmpleado",
      "actualizarDatosPromotor",
      "buscarUsuarioAuntenticado",
      "cerrarSesion",
    ]),
    ...mapActions("validacion", ["consultarUsuariosEmpleados"]),
    async logout() {
      localStorage.clear();
      // localStorage.removeItem("empleado");
      await this.cerrarSesion();
      this.$router.replace("/login");
    },
    existeEmpleado() {
      let dataEmpleado = localStorage.getItem("empleado");
      return dataEmpleado ? true : false;
    },
    validarUsuario() {

      if (this.usuario == "") {
        this.MostrarAlertaError("Proporcione un usuario", true);
      } else {
        
        this.MostrarAlertaContraseña();
      }
    },
    validarNombre() {
      if (this.nombre == "") {
        this.MostrarAlertaErrorPromotor("Proporcione un nombre", true);
      } else {
        this.MostrarAlertaDivision();
      }
    },
    async validarInicioSesion() {
      try {
        if (this.contraseña == "") {
          this.MostrarAlertaError("Proporcione una contraseña", false);
        } else {
          const accesos = await this.consultarAccesosEmpleado({
            usuario: this.usuario,
            contraseña: this.contraseña,
          });
          
          if (accesos?.length > 0) {
            const objetoEmpleado = {
              usuario: accesos[0].user,
              contraseña: accesos[0].password1,
            };
            localStorage.setItem("empleado", JSON.stringify(objetoEmpleado));
            this.MostrarAlertaExito();
          } else {
            this.MostrarAlertaError(
              "Usuario y/o contraseña incorrectos",
              false
            );
          }
        }
      } catch (error) {
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },
    async validarDatosPromotor() {
      try {
        if (this.division == "") {
          this.MostrarAlertaErrorPromotor("Proporcione una división", false);
        } else if (isNaN(this.division)) {
          this.MostrarAlertaErrorPromotor(
            "Proporcione una división válida",
            false
          );
        } else {
          const objetoPromotor = {
            nombre: this.nombre,
            division: this.division,
            uid: this.usuarioActual?.uid,
          };
          await this.actualizarDatosPromotor(objetoPromotor);

          const objetoEmpleado = {
            usuario: this.nombre,
            contraseña: "123456",
          };
          localStorage.setItem("empleado", JSON.stringify(objetoEmpleado));

          await this.buscarUsuarioAuntenticado(this.usuarioActual?.uid);

          this.MostrarAlertaExitoPromotor();
        }
      } catch (error) {
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },
    MostrarAlertaUsuario() {
      
      this.$swal({
        title: "Verifícate como empleado",
        html: `<select id="empleado" type="text" class="swal2-input" name="empleado" style="width:80%">
        </select>`,
        willOpen: async () => {
          await this.verificarVigenciaListadoEmpleados();
          const selectEmpleado = document.getElementById("empleado");
          while (selectEmpleado.firstChild) {
            selectEmpleado.removeChild(selectEmpleado.firstChild);
          }
          
          this.listaEmpleados.forEach((empleado) => {
            const option = document.createElement("option");
            option.textContent = empleado.nombre;
            option.value = empleado.usuario;
            selectEmpleado.appendChild(option);
          });

          const optionDefecto = document.createElement("option");
            optionDefecto.textContent = "- Seleccionar empleado -";
            optionDefecto.value = "";
          selectEmpleado.appendChild(optionDefecto);

          selectEmpleado.value = "";
          
        },
        showDenyButton: true,
        denyButtonText: "Cerrar Sesión",
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if(result.isConfirmed){
          const inputEmpleado = document.getElementById("empleado").value;
          this.usuario = inputEmpleado;
          this.validarUsuario(inputEmpleado);
        } else if (result.isDenied) {
          this.logout();
        }
      });
    },
    MostrarAlertaContraseña() {
      
      this.$swal({
        title: "Ingresa una contraseña",
        input: "password",
        inputLabel: "Contraseña",
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        showDenyButton: true,
        denyButtonText: "Regresar",
        allowEscapeKey: false,
      }).then((result) => {
        this.contraseña = result.value;
        if (result.isConfirmed) {
          this.validarInicioSesion();
        } else if (result.isDenied) {
          this.usuario = "";
          this.contraseña = "";
          this.MostrarAlertaUsuario();
        }
      });
    },
    MostrarAlertaError(mensaje, esCorreo) {
      this.$swal({
        icon: "warning",
        type: "warning",
        title: "Oops...",
        text: mensaje,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (esCorreo) {
          this.MostrarAlertaUsuario();
        } else {
          this.MostrarAlertaContraseña();
        }
      });
    },
    MostrarAlertaExito() {
      this.$swal({
        icon: "success",
        type: "success",
        title: "Verificación exitosa!",
      }).then((result) => {});
    },
    MostrarAlertaPromotor() {
      this.$swal({
        title: "Verifícate como promotor",
        input: "text",
        inputLabel: "Nombre",
        confirmButtonText: "Continuar",
        showDenyButton: true,
        denyButtonText: "Cerrar Sesión",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.nombre = result.value;
          this.validarNombre();
        } else if (result.isDenied) {
          this.logout();
        }
      });
    },
    seleccionarTipo(tipo) {
      
      this.tipo = tipo;
    },
    MostrarAlertaDivision() {
      
      this.$swal({
        title: "¿En que sucursal te encuentras?",
        html: `
  <h5 class="d-flex justify-content-around mb-3">Tipo de tienda: </h5>
  <div class="d-flex-column justify-content-around">

    <button
      class="btn btn-light"
      type="button"
      data-bs-original-title=""
      title=""
      id="acropolis"
      value="acropolis"

    >
      Acrópolis
    </button>
    <button
      class="btn btn-light my-2"
      type="button"
      data-bs-original-title=""
      title=""
      id="surtitienda"
      value="surtitienda"
    >
      Surtitienda
    </button>
    <button
      class="btn btn-light"
      type="button"
      data-bs-original-title=""
      title=""
      id="superkompras"
      value="superkompras"
    >
      Super Kompras
    </button>
    <button
      class="btn btn-light my-2 my-md-0"
      type="button"
      data-bs-original-title=""
      title=""
      id="superkomprasmicro"
      value="superkomprasmicro"
    >
      Super Kompras micro
    </button>
  </div>

  <div class="mt-3">
    <h5 class="mb-3">Elige tu sucursal: </h5>
      <select id="input3" class="swal2-select d-block m-auto">

      </select>
    </div>
  `,
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        showDenyButton: true,
        denyButtonText: "Regresar",
        allowEscapeKey: false,
        willOpen: () => {
          const selectElement = document.getElementById("input3");

          const acropolis = document.getElementById("acropolis");
          const surtitienda = document.getElementById("surtitienda");
          const superkompras = document.getElementById("superkompras");
          const superkomprasmicro =
            document.getElementById("superkomprasmicro");

          acropolis.addEventListener("click", () => {
            acropolis.classList.add("btn-success");
            acropolis.classList.remove("btn-light");

            surtitienda.classList.add("btn-light");
            surtitienda.classList.remove("btn-success");

            superkompras.classList.add("btn-light");
            superkompras.classList.remove("btn-success");

            superkomprasmicro.classList.add("btn-light");
            superkomprasmicro.classList.remove("btn-success");

            this.tipo = "acropolis";

            while (selectElement.firstChild) {
              selectElement.removeChild(selectElement.firstChild);
            }

            this.listaSucursalesAcropolis.forEach((sucursal) => {
              const option = document.createElement("option");
              option.value = sucursal.division;
              option.textContent = sucursal.nombre;
              selectElement.appendChild(option);
            });
          });

          surtitienda.addEventListener("click", () => {
            surtitienda.classList.add("btn-success");
            surtitienda.classList.remove("btn-light");

            superkompras.classList.add("btn-light");
            superkompras.classList.remove("btn-success");

            acropolis.classList.add("btn-light");
            acropolis.classList.remove("btn-success");

            superkomprasmicro.classList.add("btn-light");
            superkomprasmicro.classList.remove("btn-success");

            this.tipo = "surtitienda";

            while (selectElement.firstChild) {
              selectElement.removeChild(selectElement.firstChild);
            }

            this.listaSucursalesSurtitienda.forEach((sucursal) => {
              const option = document.createElement("option");
              option.value = sucursal.division;
              option.textContent = sucursal.nombre;
              selectElement.appendChild(option);
            });
          });

          superkompras.addEventListener("click", () => {
            superkompras.classList.add("btn-success");
            superkompras.classList.remove("btn-light");

            surtitienda.classList.add("btn-light");
            surtitienda.classList.remove("btn-success");

            acropolis.classList.add("btn-light");
            acropolis.classList.remove("btn-success");

            superkomprasmicro.classList.add("btn-light");
            superkomprasmicro.classList.remove("btn-success");

            this.tipo = "superkompras";

            while (selectElement.firstChild) {
              selectElement.removeChild(selectElement.firstChild);
            }

            this.listaSucursalesSk.forEach((sucursal) => {
              const option = document.createElement("option");
              option.value = sucursal.division;
              option.textContent = sucursal.nombre;
              selectElement.appendChild(option);
            });
          });

          superkomprasmicro.addEventListener("click", () => {
            superkomprasmicro.classList.add("btn-success");
            superkomprasmicro.classList.remove("btn-light");

            superkompras.classList.add("btn-light");
            superkompras.classList.remove("btn-success");

            surtitienda.classList.add("btn-light");
            surtitienda.classList.remove("btn-success");

            acropolis.classList.add("btn-light");
            acropolis.classList.remove("btn-success");

            this.tipo = "superkomprasmicro";

            while (selectElement.firstChild) {
              selectElement.removeChild(selectElement.firstChild);
            }

            this.listaSucursalesSkMicro.forEach((sucursal) => {
              const option = document.createElement("option");
              option.value = sucursal.division;
              option.textContent = sucursal.nombre;
              selectElement.appendChild(option);
            });
          });
        },
      }).then((result) => {
        const divisionSelect = document.getElementById("input3").value;
        this.division = divisionSelect;
        if (result.isConfirmed) {
          this.validarDatosPromotor();
        } else if (result.isDenied) {
          this.nombre = "";
          this.division = "";
          this.MostrarAlertaPromotor();
        }
      });
    },
    MostrarAlertaErrorPromotor(mensaje, esNombre) {
      this.$swal({
        icon: "warning",
        type: "warning",
        title: "Oops...",
        text: mensaje,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (esNombre) {
          this.MostrarAlertaPromotor();
        } else {
          this.MostrarAlertaDivision();
        }
      });
    },
    MostrarAlertaExitoPromotor() {
      localStorage.removeItem("listaEmpleados");
      this.$swal({
        icon: "success",
        type: "success",
        title: "Datos actualizados!",
      }).then((result) => {});
    },
    async verificarVigenciaListadoEmpleados() {
      const tiempoVigencia = 24 * 60 * 60 * 1000;
      const tiempoActual = moment().format("x");
      let listaEmpleadosJson = localStorage.getItem("listaEmpleadosUsuario");

      if (listaEmpleadosJson) {
        listaEmpleadosJson = JSON.parse(listaEmpleadosJson);
        
        if (tiempoActual - listaEmpleadosJson.timestamp > tiempoVigencia) {
          
          localStorage.removeItem("listaEmpleadosUsuario");
        }
      }
      await this.obtenerEmpleados(this.getUsuarioActual?.division);
    },
    async obtenerEmpleados(sucursal) {
      try {
        let listaEmpleadosJson = localStorage.getItem("listaEmpleadosUsuario");

        if (listaEmpleadosJson) {
          listaEmpleadosJson = JSON.parse(listaEmpleadosJson);
          this.listaEmpleados = [];
          for (var [key, empleado] of Object.entries(
            listaEmpleadosJson.listaEmpleados
          )) {
            this.listaEmpleados.push({
              nombre: `${empleado.name} ${empleado.lastnamefirst} ${empleado.lastnamesecond}`,
              usuario: empleado.user,
            });
          }
          
        } else {
          const listaEmpleadosResponse = await this.consultarUsuariosEmpleados(
            sucursal
          );
          

          const timestamp = moment().format("x");

          const objetoListaEmpleados = {
            timestamp: timestamp,
            listaEmpleados: listaEmpleadosResponse,
          };

          localStorage.setItem(
            "listaEmpleadosUsuario",
            JSON.stringify(objetoListaEmpleados)
          );

          for (var [key, empleado] of Object.entries(listaEmpleadosResponse)) {
            this.listaEmpleados.push({
              nombre: `${empleado.name} ${empleado.lastnamefirst} ${empleado.lastnamesecond}`,
              usuario: empleado.user,
            });
          }
        }

        
      } catch (error) {
        
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },
    sidebar_toggle(value) {
      this.sidebar_toggle_var = !value;
    },
    mobiletoggle_toggle(value) {
      this.mobileheader_toggle_var = value;
    },
    handleResize() {
      this.$store.dispatch("menu/resizetoggle");
    },
    removeoverlay() {
      this.$store.state.menu.activeoverlay = false;
      if (window.innerWidth < 991) {
        this.$store.state.menu.togglesidebar = false;
      }
      this.menuItems.filter((menuItem) => {
        menuItem.active = false;
      });
    },
    hidesecondmenu() {
      if (this.layoutobject.split(" ").includes("compact-sidebar")) {
        this.menuItems.filter((menuItem) => {
          menuItem.active = false;
        });
      }
      if (window.innerWidth < 991) {
        this.$store.state.menu.togglesidebar = false;
      }
    },
  },
};
</script>
