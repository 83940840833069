<script>
import moment from "moment";
import { toast } from "vue3-toastify";
import { mapActions, mapState } from "vuex";
export default {
  props: ["urlSiguiente"],
  data() {
    return {
      altaTarjeta: false,
      verificacionTarjeta: false,
      preregistro: false,
      altaQr: false,
    };
  },
  created() {
    this.consultarConfiguracionesCorreo();
    try {
      // this.guardarConfiguracionCorreoDefecto()
    } catch (error) {
      
    }
  },
  computed: {
    ...mapState("configuracion", ["configuracionesCorreo"]),
  },
  methods: {
    ...mapActions("configuracion", [
      "guardarConfiguracionCorreoDefecto",
      "consultarConfiguracionCorreoDefecto",
    ]),
    consultarConfiguracionesCorreo() {
      try {
        this.consultarConfiguracionCorreoDefecto();
      } catch (error) {
        
      }
    },
    async guardarConfiguarcionesCorreo() {
      const objetConfiguraciones = {
        alta: this.altaTarjeta,
        verificacion: this.verificacionTarjeta,
        qr: this.altaQr,
        preregistro: this.preregistro,
      };
      
      try {
        await this.guardarConfiguracionCorreoDefecto(objetConfiguraciones);
        toast.success(" ¡Formularios actualizados! ", {
            hideProgressBar: true,
            autoClose: 2000,
            theme: "colored",
          });
      } catch (error) {
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },
  },
  watch: {
    altaTarjeta() {
      
    },
    configuracionesCorreo(nuevasConfiguraciones) {
      const { alta, preregistro, qr, verificacion } = nuevasConfiguraciones;
      this.altaTarjeta = alta;
      this.preregistro = preregistro;
      this.altaQr = qr;
      this.verificacionTarjeta = verificacion;
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Correo por defecto</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-1" style="max-width: 900px">
      <form @submit.prevent="guardarConfiguarcionesCorreo()">
        <div class="card-body">
          <div class="mb-5">
            <h5>
              Elige en qué fromularios si se podrá usar el campo de correo
              electrónico por defecto y en cuales no
            </h5>
          </div>
          <div class="d-flex mb-2">
            <label class="col-form-label m-r-10">Alta tarjeta club</label>
            <div class="flex-grow-1 text-end icon-state">
              <label class="switch">
                <input type="checkbox" v-model="altaTarjeta" /><span
                  class="switch-state"
                ></span>
              </label>
            </div>
          </div>
          <div class="d-flex mb-2">
            <label class="col-form-label m-r-10"
              >Verificación tarjeta club</label
            >
            <div class="flex-grow-1 text-end icon-state">
              <label class="switch">
                <input type="checkbox" v-model="verificacionTarjeta" /><span
                  class="switch-state"
                ></span>
              </label>
            </div>
          </div>
          <div class="d-flex mb-2">
            <label class="col-form-label m-r-10">Pre registro</label>
            <div class="flex-grow-1 text-end icon-state">
              <label class="switch">
                <input type="checkbox" v-model="preregistro" /><span
                  class="switch-state"
                ></span>
              </label>
            </div>
          </div>
          <div class="d-flex mb-2">
            <label class="col-form-label m-r-10">Alta con Qr</label>
            <div class="flex-grow-1 text-end icon-state">
              <label class="switch">
                <input type="checkbox" v-model="altaQr" /><span
                  class="switch-state"
                ></span>
              </label>
            </div>
          </div>
        </div>
        <div class="card-footer text-end">
          <button
            class="btn btn-primary"
            :disabled="botonDesactivado"
            type="submit"
          >
            <div
              class="loader-box m-0"
              style="height: 20px"
              v-if="botonDesactivado"
            >
              <div
                class="loader-2 me-2"
                style="height: 25px !important; width: 25px !important"
              ></div>
              <span>guardando...</span>
            </div>
            <span v-else>Guardar cambios</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
.dp__input_wrap input {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
